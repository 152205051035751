import { createTheme } from "@mui/material";

const themeLight = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#BC172F",
    },
    background: {
      default: "#f6e7e71a",
      paper: "#f7f7f7",
      card:'#fff',
      shadow:'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'
    },
    text: {
      primary: "#151515",
    },
  },
  typography: {
    fontFamily: "outfit",
    color: '#151515',
    fontSize: { lg: '14px', md: '14px', sm: '12px', xs: '12px' },
    fontWeight: 300,
    h1: {
      fontSize: "32px",
      fontWeight: 900,
    },
    h2: {
      fontSize: "24px",
      fontWeight: 700,
      fontFamily: "butler",
    },
    h3: {
      fontSize: "20px",
      fontWeight: 700,
      fontFamily: "outfit",
    },
    h6: {
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "outfit",
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "outfit",
      lineHeight: "20px",
    },
    body2: {
      fontSize: "13px",
      fontWeight: 400,
      fontFamily: "outfit",
    },
    subtitle1: {
      fontSize: "13px",
      fontWeight: 500,
      fontFamily: "outfit",
    },
    subtitle2: {
      fontSize: "12px",
      color: "#8f8f8f",
      fontWeight: 500,
      fontFamily: "outfit",
    },
    button: {
      textTransform: "initial",
      fontFamily: "outfit",
      "disabled": {
        "background": "#5b5b5b"
      }
    },
  },
    components: {
    MuiBox: {
      defaultProps: {
        sx: {
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
              backgroundColor: 'background.paper',
        },
      },
    },
      MuiListItemButton: {
      styleOverrides: {
        root: {
         color:'#000',
          '&.Mui-selected': {
            backgroundColor: '#BC172f0d',
            color: '#1a1a1a',         

            '&:hover': {
              backgroundColor: '#bc172f', // Hover color when selected
            },
          },
        },
      },
    },
  },
});

export default themeLight;
