import React from "react";
import { RadioGroup, FormControlLabel, Checkbox } from "@mui/material";

const CustomCheckbox = ({ onChange, label, checked }) => {
  return (
    <>
      <FormControlLabel
        sx={{
         
          "& > span": {
            fontWeight: 400,
            fontFamily: "outfit",
            fontSize: "12px",
        
          },
        }}
        onChange={onChange}
        name={label}
        value={label}
        control={<Checkbox checked={checked} />}
        label={label}
      />
    </>
  );
};

export default CustomCheckbox;
