import React from 'react'
import Navbar from '../Header/Navbar'

const Faq = () => {
  return (
<>
<Navbar/>
</>
  )
}

export default Faq