import { CloseOutlined } from "@mui/icons-material";
import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  InputLabel,
} from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../CustomField/CustomTextField";
import { createEvents } from "../../../axios/api";
import Loader from "../Common/Loader";
import { useSnackbar } from "notistack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {lg:550, md:550, xs:350},
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0,
  maxHeight:'70vh',
  overflow:'scroll',
  borderRadius:'10px'
};
const CreateExperience = ({ action }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [eventName, setEventName] = useState("");
  const [time, setTime] = useState(0);
  const [date, setDate] = useState(0);
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(" ");
  const [textValue, setTextValue] = useState("");

  const handleChange = (value) => {
    setTextValue(value);
  };

  const handleSend = () => {
    // Send textValue to your endpoint

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleFile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleCreateEvent = async () => {
    setLoading(true);
    await createEvents(eventName, textValue, date, time, selectedFile)
      .then((res) => {
        setLoading(false);
 

        const { data } = res;
        if (data?.status) {
          handleAlert("success", `${data?.success_message}`);
          setOpen(false);
          action();
        } else {
          handleAlert("error", `${data?.error_message}`);
        }
      })
      .catch((err) => {
        setLoading(false);

        handleAlert("error", `${err.message} `);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <Button variant="contained" onClick={() => setOpen(true)}>
        Create New Experience
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="hide_scrollbar" sx={style}>
          <Box
            sx={{
              display: "flex",
              p: 2,
              px: {md:4, sm:3, xs:2},
              bgcolor: "background.card",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #BC172F",
                borderRadius:'10px 10px 0px 0px'
            }}
          >
            <Typography sx={{ fontWeight: 700, fontFamily:'optima' }}>
              Create Experience
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined sx={{  }} />
            </IconButton>
          </Box>

          <Box sx={{ p: 2,       px: {md:4, sm:3, xs:2}, mb: 5 ,   borderRadius:'0px 0px 10px 10px', bgcolor:'background.paper'}}>
            <Typography
              sx={{ fontWeight: 500, fontSize: "14px", }}
            >
              Restaurant Experience
            </Typography>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item  md={6} sm={12} xs={12}>
                <CustomTextField
                  name="Event Name"
                  value={eventName}
                  onChange={(e) => setEventName(e.target.value)}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <CustomTextField
                  name="Event Time"
                  value={time}
                  type="time"
                  onChange={(e) => setTime(e.target.value)}
                />
              </Grid>
              <Grid item  md={6} sm={12} xs={12}>
                <CustomTextField
                  type="date"
                  name="Event Date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Grid>
              <Grid item  md={6} sm={12} xs={12}>
                <CustomTextField
                  accept="image/*"
                  name="Event Image"
                  type="file"
                  onChange={handleFile}
                />
              </Grid>
              <Grid item  md={12} sm={12} xs={12}>
                <InputLabel
                  sx={{
            
                    mb: 1,
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Description
                </InputLabel>
                {/* <CustomTextField
                  name="Description"
                  multiLine
                  rows={5}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                /> */}

                <ReactQuill
                  className="editor"
                  theme="snow"
                  value={textValue}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item  md={12} sm={12} xs={12}>
                <Button
                  disabled={!eventName || !date || !time || !textValue}
                  onClick={handleCreateEvent}
                  variant="contained"
                  sx={{ mt: 4,py:1.5, borderRadius:'10px', '&:disabled':{
                    bgcolor:'#333',
                    color:'#ccc'
                  } }}
                  fullWidth
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CreateExperience;
