import {
  Box,
  List,
  ListItemButton,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  IconButton,
  InputLabel,
} from "@mui/material";
import React, { useContext, useState } from "react";
import homeIcon from "../../assets/NavIcons/home-hashtag.svg";
import { Link, useLocation } from "react-router-dom";
import basicIcon from "../../assets/NavIcons/document-text.svg";
import icon3 from "../../assets/NavIcons/clipboard-text.svg";
import icon4 from "../../assets/NavIcons/clock.svg";
import icon5 from "../../assets/NavIcons/element-1.svg";
import icon6 from "../../assets/NavIcons/element-3.svg";
import icon7 from "../../assets/NavIcons/gallery.svg";
import icon8 from "../../assets/NavIcons/menu-board.svg";
import icon9 from "../../assets/NavIcons/user.svg";
import icon10 from "../../assets/NavIcons/link.png";
import icon11 from "../../assets/NavIcons/1.svg";
import icon12 from "../../assets/NavIcons/2.svg";
import icon13 from "../../assets/NavIcons/3.svg";
import icon14 from "../../assets/NavIcons/4.svg";
import icon15 from "../../assets/NavIcons/5.svg";
import icon16 from "../../assets/NavIcons/6.svg";
import icon17 from "../../assets/NavIcons/7.svg";
import icon18 from "../../assets/NavIcons/8.svg";
import icon19 from "../../assets/NavIcons/9.svg";
import icon20 from "../../assets/NavIcons/10.svg";
import { LuClock4 } from "react-icons/lu";
import settingsIcon from "../../assets/Icons/setting-3.svg";
import { IoIosLink } from "react-icons/io";
import { AiOutlineUnlock } from "react-icons/ai";
import { TbLayoutSidebarRightExpand } from "react-icons/tb";
import { TbLayoutSidebarLeftExpand } from "react-icons/tb";
import { IoChevronDown } from "react-icons/io5";
import { IoIosArrowUp } from "react-icons/io";
import { FiChevronRight } from "react-icons/fi";

import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { ThemeContext } from "../theme/ThemeContextProvider";


const SideBar = () => {
  const theme = useTheme();
     const {  isDarkTheme } = useContext(ThemeContext);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [shrink, setShrink] = useState(false);
  const router = useLocation();
  const [collapse, setCollapse] = useState(true);
  const restaurantCreated = useSelector(
    (state) => state.restaurants.data.result
  );
  const userPermissions = useSelector(
    (state) => state?.user?.user?.Permissions
  );



const menuData = [
  {
    label: "Basic Information",
    link: "/dashboard/basic-information",
    icon: basicIcon,
    permission:true,
    icon2:icon12
    
  },
  {
    label: "Offerings",
    link: "/dashboard/offerings",
    icon: icon3,
        permission:true,
            icon2:icon13
  },
  {
    label: "Experience",
    link: "/dashboard/experience",
    icon: icon5,
    new: false,
        icon2:icon14,
    permission:userPermissions?.
AccessExperiencesAndEvents ? true : false

  },
  {
    label: "Links",
    link: "/dashboard/online-reservation",
    icon: icon10,
        icon2:icon15,
    new: false,
        permission:true
  },
  {
    label: "Gallery Photos",
    link: "/dashboard/gallery-photos",
    icon: icon7,
        icon2:icon16,
        permission:true
  },
  {
    label: "Guestbook",
    link: "/dashboard/guest-book",
    icon: icon9,
        icon2:icon17,
      permission:userPermissions?.
AccessGuestBook
 ? true : false
  },
  {
    label: "Menu",
    link: "/dashboard/menu",
    icon: icon8,
        icon2:icon18,
permission:true
  },
];
  return (
    <>
      {isMatch ? (
        <></>
      ) : (
        <>
          <Box
            className="hide_scrollbar"
            sx={{
              height: "100vh",
              width: shrink ? 70 : 280,

              overflow: "scroll",
      borderRight: isDarkTheme && '0.5px solid #222628'
,              background: isDarkTheme ? "#000" : ' linear-gradient(256deg, rgba(246,227,227,1) 63%, rgba(188,23,47,1) 100%)',

              transition: "0.2s all ease-in-out",
              "&::-webkit-scrollbar": {
                display: "none", // Hide scrollbar for WebKit browsers
              },
            }}
          >
            <Box align="right">
              <IconButton
              
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9, rotate: "2.5deg" }}
                transition={{
                  duration: 0.2,
                  ease: "easeInOut",
                }}
                component={motion.button}
                onClick={() => setShrink(!shrink)}
                sx={{    color:'text.primary',}}
              >
                {shrink ? (
                  <TbLayoutSidebarLeftExpand  style={{fontSize:'20px'}} />
                ) : (
                  <TbLayoutSidebarRightExpand style={{fontSize:'20px'}} />
                )}
              </IconButton>
            </Box>
            {shrink ? (
              <>
                <AnimatePresence mode="popLayout">
                  <Box
                    align="center"
                    className="hide_scrollbar"
                    sx={{
                      margin: "0 auto",
                      width: "85%",
                      mt: 0,
                      transition: "0.2s all ease-in-out",
                      pt: { xl: 8, lg: 6, md: 6 },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                
                        fontFamily: "outfit",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      Overview
                    </Typography>
                  </Box>
                  <List
                    sx={{ mt: 1.5, transition: "0.2s all ease-in-out" }}
                    layout
                    component={motion.div}
                    initial={{ x: -200 }}
                    exit={{ x: -200 }}
                    animate={{ x: 0, scale: 1 }}
                    transition={{ duration: 0.2, ease: "backInOut" }}
                  >
                    <Link to="/dashboard/restaurantprofile">
                      <ListItemButton
                        selected={
                          router.pathname === "/dashboard/restaurantprofile"
                        }
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          transition: "0.2s all ease-in-out",
                          "&.Mui-selected": { bgcolor: "#333" },
                        }}
                      >
                        <img src={isDarkTheme ? homeIcon : icon11}  alt="icon" width={20} />
                      </ListItemButton>
                    </Link>
                  </List>

                  {restaurantCreated && (
                    <>
                      <Box
                        align="center"
                        sx={{
                          margin: "0 auto",
                          width: "85%",
                          mt: 2,
                          transition: "0.2s all ease-in-out",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                
                            fontFamily: "outfit",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          Menu
                        </Typography>
                      </Box>
                      <List
                        sx={{ mt: 2 }}
                        layout
                        component={motion.div}
                        initial={{ x: -200 }}
                        exit={{ x: -200 }}
                        animate={{ x: 0, scale: 1 }}
                        transition={{ duration: 0.3, ease: "backInOut" }}
                      >
                        {menuData.map((nav) => (
                          <Link to={nav.link}>
                            <ListItemButton
                              selected={router.pathname === nav.link}
                              sx={{
                                display: "flex",
                                py: 1.5,
                                alignItems: "center",
                                transition: "0.2s all ease-in-out",
                       
                                ...(nav?.permission === false) && {
                                  display:'none'
                                }
                              }}
                            >
                              <img src={isDarkTheme ? nav.icon : nav.icon2} alt="icon" width={20} />
                            </ListItemButton>
                          </Link>
                        ))}
                      </List>
                      <Box sx={{ margin: "0 auto", width: "85%", mt: 2 }}>
                        <InputLabel
                          variant="h6"
                          sx={{
                    
                            fontFamily: "outfit",
                            fontSize: "12px",
                          }}
                        >
                          Configuration
                        </InputLabel>
                      </Box>
                      <List
                        sx={{ mt: 2 }}
                        component={motion.div}
                        initial={{ x: -200 }}
                        exit={{ x: -200 }}
                        animate={{ x: 0, scale: 1 }}
                        transition={{ duration: 0.3, ease: "backInOut" }}
                      >
                                 {
                        userPermissions?.AccessTablesAndFloors
&& (
                        <Link to="/dashboard/table-rooms">
                          <ListItemButton
                            selected={
                              router.pathname === "/dashboard/table-rooms"
                            }
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              transition: "0.2s all ease-in-out",
                     
                            }}
                          >
                            <img src={isDarkTheme
                              ? icon6 : icon19} alt="icon" width={20} />
                          </ListItemButton>
                        </Link>)}
         {
                        userPermissions?.AccessReservationHour
&& (
                        <Link to="/dashboard/reservation-hours">
                          <ListItemButton
                            selected={
                              router.pathname === "/dashboard/reservation-hours"
                            }
                            sx={{
                              display: "flex",
                              alignItems: "center",
                     
                            }}
                          >
                               <LuClock4 style={{color:isDarkTheme ? "#fff" : "#000", fontSize:'20px'}} />
                          </ListItemButton>
                        </Link>)}

                        <Link to="/dashboard/billings">
                          <ListItemButton
                            selected={router.pathname === "/dashboard/billings"}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                     
                            }}
                          >
                           <AiOutlineUnlock
                                style={{ fontSize: "20px", color: isDarkTheme ? "#fff" :'#000' }}
                              />
                          </ListItemButton>
                        </Link>
                      </List>
                    </>
                  )}
                         <Box sx={{ margin: "0 auto", width: "85%", mt: 2 }}>
                          <InputLabel
                            variant="h6"
                            sx={{
                      
                              fontFamily: "outfit",
                              fontSize: "14px",
                            }}
                          >
              Account Mangement
                          </InputLabel>
                        </Box>
                        <List sx={{ mt: 2 }}>
                          <Link to="/dashboard/account">
                            <ListItemButton
                            // disabled
                              selected={
                                router.pathname === "/dashboard/account"
                              }
                              sx={{
                                display: "flex",
                                alignItems: "center",
                       
                              }}
                            >
                             <img src={isDarkTheme ? settingsIcon : icon20} alt="icon" width={20} />
                       
                            </ListItemButton>
                          </Link>

                    

                  
                        </List>
                </AnimatePresence>
              </>
            ) : (
              <>
                <AnimatePresence mode="popLayout">
                  <Box>
                    <Box
                      sx={{
                        margin: "0 auto",
                        width: "85%",
                        mt: 0,
                        pt: { xl: 8, lg: 6, md: 6 },
                      }}
                      component={motion.div}
                      initial={{ x: 200 }}
                      exit={{ x: 200 }}
                      animate={{ x: 0, scale: 1 }}
                      transition={{ duration: 0.3, ease: "backInOut" }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                  
                          fontFamily: "outfit",
                          fontSize: "14px",
                        }}
                      >
                        Overview
                      </Typography>
                    </Box>
                    <List
                      sx={{ mt: 1.5 }}
                      component={motion.div}
                      initial={{ x: 200 }}
                      exit={{ x: 200 }}
                      animate={{ x: 0, scale: 1 }}
                      transition={{ duration: 0.3, ease: "backInOut" }}
                    >
                      <Link to="/dashboard/restaurantprofile">
                        <ListItemButton
                          selected={
                            router.pathname === "/dashboard/restaurantprofile"
                          }
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            "&.Mui-selected": { 
                                          borderRight:  !isDarkTheme && '5px solid #bc172f',
                            },
                          }}
                        >
                          <img src={isDarkTheme ? homeIcon : icon11 } alt="icon" width={20} />
                          <Typography
                            sx={{
                              fontFamily: "outfit",
                    
                              ml: 2,
                              mt: 1,
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            {" "}
                            Restaurant Profile{" "}
                          </Typography>
                        </ListItemButton>
                      </Link>
                    </List>

                    {restaurantCreated && (
                      <>
                        <Box
                        onClick={()=>setCollapse(!collapse)}
                          sx={{
                            margin: "0 auto",
                            width: "85%",
                            mt: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            alignItems: "center",
                            transition: "0.2s all linear",
                            "&:hover": {
                              borderTop: "1px solid #242424",
                              px: 1,
                            },
                          }}
                          component={motion.div}
                          initial={{ x: 200 }}
                          exit={{ x: 200 }}
                          animate={{ x: 0, scale: 1 }}
                          transition={{ duration: 0.3, ease: "backInOut" }}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9, rotate: "2.5deg" }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                      
                              fontFamily: "outfit",
                              fontSize: "14px",
                            }}
                          >
                            Menu
                          </Typography>
                          <IconButton sx={{color:'text.primary'}}>
                            {
                              !collapse ? <IoChevronDown       style={{  fontSize: "15px" }} /> :   <FiChevronRight
                              style={{  fontSize: "15px" }}
                            />
                            }
                          
                          </IconButton>
                        </Box>
                        <List
                          sx={{ mt: 2, transition:'0.2s all linear', height:collapse ? '0px' : 'auto', overflow:'hidden'}}
                          component={motion.div}
                          initial={{ x: 200 }}
                          exit={{ x: 200 }}
                          animate={{ x: 0, scale: 1 }}
                          transition={{ duration: 0.3, ease: "backInOut" }}
                        >
                          {menuData.map((nav) => (
                            <Link to={nav.link}>
                              <ListItemButton
                                selected={router.pathname === nav.link}
                                sx={{
                                  display: "flex",
                                  py: 1.5,
                                  alignItems: "center",
                                         "&.Mui-selected": { 
                                          borderRight:  !isDarkTheme && '5px solid #bc172f',
                            },
                         
                                     ...(nav?.permission === false) && {
                                  display:'none'
                                }
                                }}
                              >
                                <img src={isDarkTheme ? nav.icon : nav.icon2} alt="icon" width={20} />
                                <Typography
                                  sx={{
                                    fontFamily: "outfit",
                          
                                    fontSize: "12px",
                                    ml: 2,
                                    mt: 1,
                                    fontWeight: 500,
                                  }}
                                >
                                  {" "}
                                  {nav.label}{" "}
                                  {nav.new && (
                                    <span
                                      style={{
                                        background: "#C1E647",
                                        padding: "2px",
                                        borderRadius: "2px",
                                        fontWeight: 600,
                                        fontSize: "8px",
                                        color: "#000",
                                        // marginTop:'-50px'
                                      }}
                                    >
                                      New
                                    </span>
                                  )}
                                </Typography>
                              </ListItemButton>
                            </Link>
                          ))}
                        </List>
                        <Box sx={{ margin: "0 auto", width: "85%", mt: 2 }}>
                          <Typography
                            variant="h6"
                            sx={{
                      
                              fontFamily: "outfit",
                              fontSize: "14px",
                            }}
                          >
                            Configuration
                          </Typography>
                        </Box>
                        <List sx={{ mt: 2 }}>
                          {
                            userPermissions?.
AccessTablesAndFloors && (
   <Link to="/dashboard/table-rooms">
                            <ListItemButton
                              selected={
                                router.pathname === "/dashboard/table-rooms"
                              }
                              sx={{
                                display: "flex",
                                alignItems: "center",
                       py:2,
                              "&.Mui-selected": { 
                                          borderRight:  !isDarkTheme && '5px solid #bc172f',
                            },
                              }}
                            >
                              <img src={ isDarkTheme ? icon6 : icon19} alt="icon" width={20} />
                              <Typography
                                sx={{
                                  fontFamily: "outfit",
                        
                                  ml: 2,

                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                {" "}
                                Tables & Rooms
                              </Typography>
                            </ListItemButton>
                          </Link>
)
                          }
                       {
                        userPermissions?.AccessReservationHour
&& (
      <Link to="/dashboard/reservation-hours">
                            <ListItemButton
                              selected={
                                router.pathname ===
                                "/dashboard/reservation-hours"
                              }
                              sx={{
                                display: "flex",
                                alignItems: "center",
                       py:2,

                              "&.Mui-selected": { 
                                          borderRight:  !isDarkTheme && '5px solid #bc172f',
                            },
                              }}
                            >
                              <LuClock4 style={{color:isDarkTheme ? "#fff" : "#000", fontSize:'18px'}} />
                              {/* <img src={icon4} alt="icon" width={20} /> */}
                              <Typography
                                sx={{
                                  fontFamily: "outfit",
                        
                                  ml: 2,

                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                {" "}
                                Reservation Hours{" "}
                              </Typography>
                            </ListItemButton>
                          </Link>
)
                       }

                    

                          <Link to="/dashboard/billings">
                            <ListItemButton
                              selected={
                                router.pathname === "/dashboard/billings"
                              }
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                py:2,
                                       "&.Mui-selected": { 
                                          borderRight:  !isDarkTheme && '5px solid #bc172f',
                            },
                       
                              }}
                            >
                              <AiOutlineUnlock
                                style={{ fontSize: "20px", color: isDarkTheme ? "#fff" :'#000' }}
                              />
                              <Typography
                                sx={{
                                  fontFamily: "outfit",
                        
                                  ml: 2,

                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                {" "}
                                Billings
                              </Typography>
                            </ListItemButton>
                          </Link>
                        </List>
                      </>
                    )}
                  </Box>
                           <Box sx={{ margin: "0 auto", width: "85%", mt: 2 }}>
                          <Typography
                            variant="h6"
                            sx={{
                      
                              fontFamily: "outfit",
                              fontSize: "14px",
                            }}
                          >
              Account Mangement
                          </Typography>
                        </Box>
                        <List sx={{ mt: 2, }}>
                          <Link to="/dashboard/account">
                            <ListItemButton
                            // disabled
                              selected={
                                router.pathname === "/dashboard/account"
                              }
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                py:2,
                                       "&.Mui-selected": { 
                                          borderRight:  !isDarkTheme && '5px solid #bc172f',
                            },
                       
                              }}
                            >
                              <img src={isDarkTheme ? settingsIcon : icon20} alt="icon" width={20} />
                              <Typography
                                sx={{
                                  fontFamily: "outfit",
                        
                                  ml: 2,

                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                              >
                                {" "}
                              Account                             </Typography>
                            </ListItemButton>
                          </Link>

                    

                  
                        </List>
                </AnimatePresence>
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default SideBar;
