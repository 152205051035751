import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useDrop } from "react-dnd";
import Draggable from "react-draggable";

import ReactFlow , { useNodesState, useEdgesState, addEdge, Background, MiniMap, Controls } from 'react-flow-renderer'
import CustomElement from "./CustomElement";

const BluePrint = ({
  bluePrintElement,
 handleEditFloor,
  updateSelectedNode,
  handleRightClick,
  selectedElement,
}) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "notes",
      drop: () => ({ name: "the bin" }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    []
  );





const [convertedBluePrint, setConvertedBluePrint] = useState([])
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
useEffect(()=>{
  if(bluePrintElement){
const transformedData = bluePrintElement.map(item => ({
  id: item.Id,
  type: "custom", // Assuming "custom" is the type you want for all nodes
  position: {
    x: item.Point.x,
    y: item.Point.y,
  },
  data: {
    Name: item.Name,
    label:item.Name,
    Type: item.Type,
    MaxSize: item?.MaxSize,
    MinSize: item.MinSize,
    Position: item.Position,
    Setting: item.Setting,
    Shape: item.Shape, // Assuming the shape corresponds to the image key
  }
}));
// setConvertedBluePrint(transformedData)
setNodes(transformedData)

  }
}, [bluePrintElement])





  const handleNodeDragStop = (event, node) => {
    const updatedNodes = nodes?.map((n) => (n.id === node.id ? { ...n, position: node.position } : n));
const object = updatedNodes.filter((item) => item.id === node.id);

    updateSelectedNode(object[0]);
  
    setNodes(updatedNodes)
// setConvertedBluePrint(updatedNodes)
// handleDragStopped(node)
  };

  

    const handleNodeClick = (event, node) => {
          const updatedNodes = nodes?.map((n) => (n.id === node.id ? { ...n, position: node.position } : n));
      const object = updatedNodes.filter((item) => item.id === node.id);

    updateSelectedNode(object[0]);

    // Add your logic here
  };

    const nodeTypes = { custom: CustomElement };

  return (
    <Box
      ref={drop}
      sx={{
        bgcolor: "#0e0d0d",
        borderRadius: "4px",
        border: "1px solid #242424",
        boxSizing: "border-box",
        height: "700px",
      width: "100%",

      }}
 
    >
   <ReactFlow nodes={nodes}    fitView snapToGrid={true}     onNodeDragStop={handleNodeDragStop}    onNodeClick={handleNodeClick}   nodeTypes={nodeTypes} >
      <Controls/>
      {/* <MiniMap/> */}
      <Background color='#ccc'/>
      </ReactFlow>

  
    </Box>
  );
};

export default BluePrint;


const convertToNodeFormat = (data) => {
  const imageMap = {
    square: 'path/to/square/image', // replace with actual image paths
    tableRec: 'path/to/tableRec/image',
    // Add other shapes/types as needed
  };

  return {
    id: data.Id, // use Id as the id
    type: 'custom', // set type to custom
    position: {
      x: data.Point.x, // map Point.x to position.x
      y: data.Point.y, // map Point.y to position.y
    },
    data: {
      label: data.Name, // map Name to label
      maxSize: data.MaxSize, // map MaxSize to data property
      minSize: data.MinSize, // map MinSize to data property
      setting: data.Setting, // map Setting to data property
      shape: data.Shape, // map Shape to data property
      image:data.Type, // map Shape to corresponding image
      type: data.Type, // map Type to data property
    },
  };
};








    {/* {bluePrintElement?.map((node, i) => (
    
     
            <Draggable

              defaultPosition={{
                x: node.Point?.x,
                y: node.Point?.y,
              }}
    
              onStop={(e, data) => handleDragStop(e, data, node)} // Pass the node to handleDragStop
            >
              <div
                style={{ width: "fit-content" , position:'relative', }}
                onClick={() => handleClicked(node)}
                onContextMenu={(e) => handleRightClick(e, node)}
              >
                {node?.Type === "tableRec" && (
               
                    <Box
                      sx={{
                        background: `url('${svgRec}')`,
                        width: "100px",
                        height: "100px",
                        backgroundSize: "100px",
                        transition: "0.2s all linear",
                        "&:hover": { cursor: "move", borderRadius: "5px" },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: 2,
                        ...(selectedElement === node && {
                          cursor: "move",
                          borderRadius: "5px",
                          border: "1px dashed red",
                        }),
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 500,
                        }}
                      >
                        {node?.Name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <img src={group} width={8} />

                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "8px",
                            fontWeight: 500,
                          }}
                        >
                          {`${node?.MinSize}-${node?.MaxSize}`}
                        </Typography>
                      </Box>
                    </Box>
              
                )}
                {node?.Type === "tableSquare" && (
                  <IconButton>
                    <Box
                      sx={{
                        background: `url('${svgSquare}')`,
                        width: "200px",
                        height: "110px",
                        backgroundSize: "200px",
                        transition: "0.2s all linear",
                        "&:hover": { cursor: "move", borderRadius: "5px" },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: 2,
                        ...(selectedElement === node && {
                          cursor: "move",
                          borderRadius: "5px",
                          border: "1px dashed red",
                        }),
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 500,
                        }}
                      >
                        {node?.Name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <img src={group} width={8} />

                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          {`${node?.MinSize}-${node?.MaxSize}`}
                        </Typography>
                      </Box>
                    </Box>
                  </IconButton>
                )}
                {node?.Type === "tableCircle" && (
                  <IconButton>
                    <Box
                      sx={{
                        background: `url('${svgCircular}')`,
                        width: "150px",
                        height: "150px",
                        backgroundSize: "150px",
                        transition: "0.2s all linear",
                        "&:hover": { cursor: "move", borderRadius: "5px" },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: 3,
                        ...(selectedElement === node && {
                          cursor: "move",
                          borderRadius: "5px",
                          border: "1px dashed red",
                        }),
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 500,
                        }}
                      >
                        {node?.Name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <img src={group} width={8} />

                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          {`${node?.MinSize}-${node?.MaxSize}`}
                        </Typography>
                      </Box>
                    </Box>
                  </IconButton>
                )}
                {node?.Type === "tableOval" && (
                  <IconButton>
                    <Box
                      sx={{
                        background: `url('${svgOval}')`,
                        width: "100px",
                        height: "100px",
                        backgroundSize: "100px",
                        transition: "0.2s all linear",
                        "&:hover": { cursor: "move", borderRadius: "5px" },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: 3,
                        ...(selectedElement === node && {
                          cursor: "move",
                          borderRadius: "5px",
                          border: "1px dashed red",
                        }),
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "10px",
                          fontWeight: 500,
                        }}
                      >
                        {node?.Name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 1,
                        }}
                      >
                        <img src={group} width={8} />

                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "10px",
                            fontWeight: 500,
                          }}
                        >
                          {`${node?.MinSize}-${node?.MaxSize}`}
                        </Typography>
                      </Box>
                    </Box>
                  </IconButton>
                )}
                {node?.Type === "bar" && (
                  <IconButton sx={{ borderRadius: "3px" }}>
                    <Box
                      sx={{
                        background: `url('${svgBar}')`,
                        width: "250px",
                        height: "110px",
                        backgroundSize: "250px",
                        transition: "0.2s all linear",
                        "&:hover": { cursor: "move", borderRadius: "5px" },
                      }}
                    ></Box>
                  </IconButton>
                )}
              </div>
            </Draggable>
       

  
      ))} */}

