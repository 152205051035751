import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import delIcon from "../../../assets/Icons/delete.svg";
import { IoTrashBinSharp } from "react-icons/io5";

const MenuTable = ({ data }) => {

  return (
    <>
      <Table
        sx={{ border: "0.5px solid", borderRadius: "0px  0px 10px 10px" ,       borderColor:'text.primary',}}
      >
        <TableHead
          sx={{
            bgcolor: "background.card",
            height: "50px",
            border: "0.5px solid ",
            borderColor:'text.primary',
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
          }}
        >
          <TableRow>
            <TableCell
              sx={{
         
                textAlign: "center",
                fontFamily: "outfit",
          
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Menu Item
            </TableCell>
            <TableCell
              sx={{
            
                textAlign: "center",
                fontFamily: "outfit",
          
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Description
            </TableCell>
            <TableCell
              sx={{
             
           
                textAlign: "center",
                fontFamily: "outfit",
          
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Price
            </TableCell>
            <TableCell
              sx={{
                
        
                textAlign: "center",
                fontFamily: "outfit",
          
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Category
            </TableCell>
            <TableCell
              sx={{
           
                textAlign: "center",
                fontFamily: "outfit",
          
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              Image
            </TableCell>
            <TableCell align="center" sx={{}}>
<IconButton sx={{color:'text.primary'}}>
  <IoTrashBinSharp style={{fontSize:'16px'}} />
</IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {data &&
              data[0].map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      borderRight: "0.2px solid #fff",
                      textAlign: "center",
                      fontSize: "12px",
                
                      fontFamily: "outfit",
                    }}
                  >
                    {item?.MenuItem}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "30%",
                      borderRight: "0.2px solid #fff",
                      textAlign: "center",
                      fontSize: "12px",
                
                      fontFamily: "outfit",
                    }}
                  >
                    {item?.Description}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "0.2px solid #fff",
                      textAlign: "center",
                      fontSize: "12px",
                
                      fontFamily: "outfit",
                    }}
                  >
                    {" "}
                    ₦{item?.Price?.toLocaleString()}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "0.2px solid #fff",
                      textAlign: "center",
                      fontSize: "12px",
                
                      fontFamily: "outfit",
                    }}
                  >
                    {item?.Category}
                  </TableCell>
                  <TableCell sx={{ borderRight: "0.2px solid #fff" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>
                      <Box
                        sx={{
                          width: "156px",
                          height: "70px",
                          background: `url('${item?.Image}')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                      <IconButton sx={{color:'text.primary'}}>
  <IoTrashBinSharp style={{fontSize:'16px'}} />
</IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {data && data[1] &&
              data[1].map((item, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      borderRight: "0.2px solid #fff",
                      textAlign: "center",
                      fontSize: "12px",
                
                      fontFamily: "outfit",
                    }}
                  >
                    {item?.MenuItem}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "30%",
                      borderRight: "0.2px solid #fff",
                      textAlign: "center",
                      fontSize: "12px",
                
                      fontFamily: "outfit",
                    }}
                  >
                    {item?.Description}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "0.2px solid #fff",
                      textAlign: "center",
                      fontSize: "12px",
                
                      fontFamily: "outfit",
                    }}
                  >
                    {" "}
                    ₦{item?.Price?.toLocaleString()}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "0.2px solid #fff",
                      textAlign: "center",
                      fontSize: "12px",
                
                      fontFamily: "outfit",
                    }}
                  >
                    {item?.Category}
                  </TableCell>
                  <TableCell sx={{ borderRight: "0.2px solid #fff" }}>
                    <Box sx={{ display: "grid", placeItems: "center" }}>
                      <Box
                        sx={{
                          width: "156px",
                          height: "70px",
                          background: `url('${item?.Image}')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      ></Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                 <IconButton sx={{color:'text.primary'}}>
  <IoTrashBinSharp style={{fontSize:'16px'}} />
</IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </>
        </TableBody>
      </Table>
    </>
  );
};

export default MenuTable;
