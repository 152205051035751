import React, { createContext, useState, useMemo, useContext, useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import themeLight from './themeLight';
import themeDark from './themeDark';

const ThemeContext = createContext({
  toggleTheme: () => {},
  isDarkTheme: false,
});

const ThemeContextProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(() => {
    // Check localStorage for saved theme preference
    const savedTheme = localStorage.getItem('isDarkTheme');
    return savedTheme ? JSON.parse(savedTheme) : true; // default to light theme
  });

  const toggleTheme = () => {
    setIsDarkTheme((prevTheme) => {
      const newTheme = !prevTheme;
      localStorage.setItem('isDarkTheme', JSON.stringify(newTheme)); // Save to localStorage
      return newTheme;
    });
  };

  const theme = useMemo(
    () => (isDarkTheme ? themeDark : themeLight),
    [isDarkTheme]
  );

  useEffect(() => {
    // Optional: You can synchronize the localStorage value when the component mounts
    const savedTheme = localStorage.getItem('isDarkTheme');
    if (savedTheme !== null) {
      setIsDarkTheme(JSON.parse(savedTheme));
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ toggleTheme, isDarkTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
