import React, { useState, useEffect } from "react";
import { Avatar, Box, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { useDrop } from "react-dnd";
import layAnime from "../../../assets/anime/lay.json";
import Lottie from "react-lottie";
import CustomElementGuest from "../Tables/CustomElementGuest";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Background,
  MiniMap,
  Controls,
} from "react-flow-renderer";

const BluePrint = ({
  bluePrintElement,
  floorplan,
  updateSelectedFloor,
  selectedFloor,
  selectedReservation
}) => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "notes",
      drop: () => ({ name: "the bin" }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    []
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: layAnime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [convertedBluePrint, setConvertedBluePrint] = useState([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  useEffect(() => {
    if (bluePrintElement) {
      const transformedData = bluePrintElement.map((item) => ({
        id: item.Id,
        type: "custom", // Assuming "custom" is the type you want for all nodes
        position: {
          x: item.Point.x,
          y: item.Point.y,
        },
        data: {
          Name: item.Name,
          label: item.Name,
          Type: item.Type,
          MaxSize: item?.MaxSize,
          MinSize: item.MinSize,
          Position: item.Position,
          Setting: item.Setting,
          Shape: item.Shape,
status:item.
BookingStatus || null

        },
      }));
      // setConvertedBluePrint(transformedData)
      setNodes(transformedData);
    }
  }, [bluePrintElement]);

  const nodeTypes = { custom: CustomElementGuest };


  return (
    <>
      {!bluePrintElement && (
        <Box
          sx={{
            height: "700px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Lottie options={defaultOptions} width={150} height={150} />
        </Box>
      )}
      {bluePrintElement && (
        <Box
          ref={drop}
          sx={{
            m: 1.5,
            boxSizing: "border-box",
            border: "0.5px solid #fff",
            borderRadius: "4px",

            boxSizing: "border-box",
            mt: 3,
          }}
          // className="grid_lines"
        >
          <Box sx={{ height: "70vh" }}>
            <ReactFlow
              nodes={nodes}
              fitView
              snapToGrid={true}
              nodeTypes={nodeTypes}
            >
              <Controls />
              {/* <MiniMap nodeColor={"#333"}/> */}
              {/* <Background color='#ccc'/> */}
            </ReactFlow>
          </Box>
          <Box
            align="right"
            sx={{ bgcolor: "#333", p: 2, boxSizing: "border-box" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 2,
                width: "50%",
                justifyContent: "right",
                overflow: "scroll",
              }}
              className="hide_scrollbar"
            >
              {floorplan?.map((item, i) => (
                <MenuItem
                  key={i}
                  selected={item?.Id === selectedFloor}
                  onClick={() => updateSelectedFloor(item?.Id)}
                  sx={{
                    color: "#fff",
                    fontSize: "12px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    "&.Mui-selected": {
                      bgcolor: "#555555",
                    },
                  }}
                >
                  {item?.Name}
                </MenuItem>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BluePrint;
