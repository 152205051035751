import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  InputLabel,
  TextField,
  Autocomplete,
  ThemeProvider,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import restaurantLogo from "../../../assets/ressta.jpg";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { getBasicInfo } from "../../../axios/api";
import Loader from "../../Components/Common/Loader";
import Section1 from "../../Components/Edits/Offerings/BasicInfo/Section1";
import Section3 from "../../Components/Edits/Offerings/BasicInfo/Section3";
import Section2 from "../../Components/Edits/Offerings/BasicInfo/Section2";
import { Helmet } from "react-helmet-async";
import SEO from "../../../Utility/SEO";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
};

const BasicInformation = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const hanleGetBasic = async () => {
    setIsLoading(true);
    await getBasicInfo()
      .then((res) => {
        setIsLoading(false);
        setData(res?.data?.result[0]);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    hanleGetBasic();
  }, []);
  return (
    <>
      <SEO
        title="Reisty | Restaurant Basic Informations"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
          canonicalUrl="https://restaurant.reisty.com/dashboard/basic-information"
      />
      {isLoading && <Loader />}
      <Header title="Basic Information Management" description="Manage and update essential restaurant details, including name, location, contact information, and logo" />
      <Box
        sx={{
          margin: "0 auto",
          width: "95%",
          height: "90vh",
          overflow: "scroll",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for WebKit browsers
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
            columnGap: 3,
            px: 2,
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
      
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Name, contact & price
            </Typography>
            <Typography
            variant="caption"
              sx={{
            
                fontSize: { lg: "12px", sm: "12px", xs: "11px" },
                fontWeight: 300,
                lineHeight: "20px",
              }}
            >
              Our map feature is linked to your restaurant name and address.
            </Typography>
          </Box>
          <Box>
            <Section1 data={data} action={hanleGetBasic} />
          </Box>
        </Box>
        <Box sx={{ bgcolor: "background.card", borderRadius: "5px", mt: 2, px: 3, py: 3 , boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
          <Grid
            container
            sx={{ width: { lg: "75%", md: "100%", sm: "100%", xs: "100%" } }}
            spacing={2}
          >
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                Restaurant Name:
              </Typography>
            </Grid>
            <Grid item lg={8} md={6} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {data ? data?.RestaurantName : "--"}
              </Typography>
            </Grid>
            {/* Restaurant Admin: */}
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                Restaurant Admin:
              </Typography>
            </Grid>
            <Grid item lg={8} md={6} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {data ? data?.AdminFullName : "--"}
              </Typography>
            </Grid>
            {/* Restaurant Website: */}
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                Restaurant Website:
              </Typography>
            </Grid>
            <Grid item lg={8} md={6} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                {data ? (
                  <a
                    target="_blank"
                    href={`${data?.Restaurantwebsite}`}
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          lg: "14px",
                          md: "14px",
                          sm: "12px",
                          xs: "12px",
                        },
            color:'text.primary',
                        fontWeight: 400,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data?.Restaurantwebsite}
                    </Typography>
                  </a>
                ) : (
                  "--"
                )}
              </Typography>
            </Grid>
            {/* Restaurant Email: */}
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                Restaurant Email:
              </Typography>
            </Grid>
            <Grid item lg={8} md={6} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {data ? data?.EmailAddress : "--"}
              </Typography>
            </Grid>
            {/* Restaurant Phone Number */}
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                Restaurant Phone Number:
              </Typography>
            </Grid>
            <Grid item lg={8} md={6} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                {data ? data.PhoneNumaber : "--"}
              </Typography>
            </Grid>

            {/* Restaurant Address */}
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                Restaurant Address:
              </Typography>
            </Grid>
            <Grid item md={8} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                  lineHeight: "20px",
                }}
              >
                {data ? data?.Address : "--"}
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                State
              </Typography>
            </Grid>
            <Grid item md={8} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {data ? data?.State : "--"}
              </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                LGA
              </Typography>
            </Grid>
            <Grid item md={8} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {data ? data?.LGA : "--"}
              </Typography>
            </Grid>
            {/* Typical check amount: */}
            <Grid item lg={4} md={4} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                Typical check amount:
              </Typography>
            </Grid>
            <Grid item lg={8} sm={6} xs={6}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
               ₦{data ? data?.AverageCost?.toLocaleString() : "--"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

   <Grid container spacing={4}>
    <Grid item md={6}>
           <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 6,
            columnGap: 3,
height:'80px'
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
    
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Restaurant Logo
            </Typography>
            <Typography
            variant="caption"
              sx={{
                 fontSize: { lg: "12px", sm: "12px", xs: "11px" },
                fontWeight: 300,
                lineHeight: "20px",
              }}
            >
              This will appear at the top left of your dashboard
            </Typography>
          </Box>
          <Box>
            <Section2 data={data} action={hanleGetBasic} />
          </Box>
        </Box>
        <Box sx={{  bgcolor: "background.card", borderRadius: "5px", mt:1, px: 3, py: 3 , boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", minHeight:'230px', boxSizing:'border-box'}}>
          <Avatar
            src={data && data?.Logo}
            variant="square"
            sx={{
              width: { lg: "60%", md: "60%", sm: "70%", xs: "100%" },
              height: "200px",border:'1px solid background.primary', borderRadius:'8px'
            }}
          />
        </Box>
    </Grid>
    <Grid item md={6}>
           <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 6,
            columnGap: 2,
            height:'80px'
          }}
        >
          <Box >
            <Typography
              variant="subtitle1"
              sx={{
    
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Description
            </Typography>
            <Typography
              sx={{
                   fontSize: { lg: "12px", sm: "12px", xs: "11px" },
                fontWeight: 300,
                lineHeight: "20px",
              }}
            >
            Create a unique description to help diners discover what sets your restaurant apart.
            </Typography>
          </Box>
          <Box>
            <Section3 data={data} action={hanleGetBasic} />
          </Box>
        </Box>
        <Box
          sx={{
         bgcolor: "background.card", borderRadius: "5px", mt:1, px: 3, py: 3 , boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", minHeight:'250px', boxSizing:'border-box'
          }}
        >
          <Grid container sx={{ width: "100%" }} spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                sx={{
                  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                Description:
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                sx={{
                  fontSize: { lg: "12px", md: "12px", sm: "12px", xs: "12px" },
      
                  fontWeight: 400,
                }}
              >
                {data ? data?.Description : "No description"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
    </Grid>
   </Grid>
   
      </Box>

      {/* Modal for Name, contact and price */}
    </>
  );
};

export default BasicInformation;
