import React from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const COLORS = ["#FF0025", "#fff"];

const Statistics = ({ data }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const datas = [
    { name: "Group A", value: data?.TotalOnline },
    { name: "Group B", value: data?.TotalWalkIn },
  ];
  const total = data?.TotalOnline + data?.TotalWalkIn;
  return (
    <>
      {total === 0 && (
        <>
          <Box
            sx={{
              width: { md: "200px ", sm: "200px", xs: "150px" },
              height: { md: "200px ", sm: "200px", xs: "150px" },
              borderRadius: "50%",
              border: "20px solid #999999b7",
              mt: 5,
              ml: { xl: -12, lg: -25, md: -23, xs: -25 },
              display: "grid",
              placeItems: "center",
            }}
          >
            <Typography
              sx={{
               
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              No Reservation
            </Typography>
          </Box>
        </>
      )}
    {
      isMobile ? (
        <>
<PieChart width={300} height={300}>
        <Pie
          data={datas}
          cx={140}
          cy={120}
          innerRadius={60}
          outerRadius={90}
          fill="#8884d8"
          paddingAngle={2}
          dataKey="value"
        >
          {datas.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
        </>
      ):(
        <>
<PieChart width={500} height={400}>
        <Pie
          data={datas}
          cx={170}
          cy={150}
          innerRadius={100}
          outerRadius={120}
          fill="#8884d8"
          paddingAngle={2}
          dataKey="value"
        >
          {datas.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
        </>
      )
    }
    </>
  );
};

export default Statistics;
