import React from "react";
import { useDrop, useDrag } from "react-dnd";
import { Box, IconButton } from "@mui/material";
import svgSquare from "../../../assets/elements/Seating Plan.svg";
import svgRec from "../../../assets/elements/Seating Plan_rec.svg";
import svgCircular from "../../../assets/elements/Seating Plan_circle.svg";
import svgOval from "../../../assets/elements/Seating Plan_oval.svg";
import svgBar from "../../../assets/elements/Seating Plan_bar.svg";

const Elements = ({
  node,
  bluePrintElement,
  updateTable,
  handleAddMore,
  updatedTables,
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "notes",
      item: node, // Assuming node is an object
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          const newObject = {
            Id: new Date().getTime().toString(),
            MaxSize: item.MaxSize,
            MinSize: item.MinSize,
            Name: item.Name,
            Point: item?.Point,

            Position: "string",
            Setting: "Casual",
            Shape: "square",
            Type: item.Type,
          };
          const updatedArray = [...updatedTables, newObject];
          updateTable(updatedArray);


          handleAddMore(item?.Name, item?.MaxSize, item?.MinSize, "Front Row", "Formal", "square", item?.Type, item?.Point)
        }
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    []
  );
  return (
    <>
      <Box
        ref={drag}
        sx={{
          height: "100px",
          display: "grid",
          placeItems: "center",
          borderBottom: "0.5px solid #242424",
          borderLeft: "0.5px solid #242424",
        }}
      >
        {node?.Type === "tableRec" && (
          <IconButton sx={{ borderRadius: "3px" }}>
            <img src={svgRec} width={70} />
          </IconButton>
        )}
        {node?.Type === "tableRec2" && (
          <IconButton sx={{ borderRadius: "3px" }}>
            <img src={svgRec} width={50} />
          </IconButton>
        )}
        {node?.Type === "tableRec3" && (
          <IconButton sx={{ borderRadius: "3px" }}>
            <img
              src={svgRec}
              width={50}
              style={{ transform: "rotate(30deg" }}
            />
          </IconButton>
        )}
        {node?.Type === "tableSquare" && (
          <IconButton sx={{ borderRadius: "3px" }}>
            <img src={svgSquare} width={70} />
          </IconButton>
        )}
        {node?.Type === "tableSquare2" && (
          <IconButton sx={{ borderRadius: "3px" }}>
            <img
              src={svgSquare}
              width={70}
              style={{ transform: "rotate(90deg" }}
            />
          </IconButton>
        )}
        {node?.Type === "tableCircle" && (
          <IconButton sx={{ borderRadius: "3px" }}>
            <img src={svgCircular} width={70} />
          </IconButton>
        )}
        {node?.Type === "tableOval" && (
          <IconButton sx={{ borderRadius: "3px" }}>
            <img src={svgOval} width={70} />
          </IconButton>
        )}
        {node?.Type === "bar" && (
          <IconButton sx={{ borderRadius: "3px" }}>
            <img src={svgBar} width={70} />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default Elements;
