import { Box, ThemeProvider, Typography, useTheme, createTheme } from "@mui/material";
import React from "react";
import Navbar from "../Component/Header/Navbar";
import RequestForm from "../Component/Common/RequestForm";
import Footer from "../Component/Footer/Footer";
import { Helmet } from "react-helmet-async";
import SEO from "../../Utility/SEO";

const theme = createTheme({
  palette: {
    primary: {
      main: "#BC172F",
    },
    mode:'light'
  },
  typography: {
    fontFamily: "outfit",
    h1: {
      fontFamily: "outfit",
      fontWeight: 700,
      fontSize: "45px",
      color: "#fff",
      lineHeight: "58px",
    },
    h2: {
      fontFamily: "outfit",
      fontWeight: 300,
      color: "#fff",
      fontSize: "20px",
      lineHeight: "30px",
    },
    button: {
      fontFamily: "outfit",
      textTransform: "initial",
    },
    caption: {
      fontFamily: "outfit",
      fontWeight: 300,
      fontSize: "14px",
      color: "#2B2B2B",
    },
    subtitle1: {
      fontFamily: "outfit",
      color: "#000",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "34px",
    },
    h3: {
      fontFamily: "outfit",
      fontWeight: 700,
      color: "#333333",
      fontSize: "64px",
    },
    body1: {
      fontFamily: "outfit",
      fontWeight: 300,
      fontSize: "15px",
      color: "#00000080",
      lineHeight: "28px",
      letterSpacing: "0.01em",
    },
  },
});
const GetStarted = () => {
  const theme = useTheme();
  return (
    <>
    <ThemeProvider theme={theme}>
      <SEO
        title="Reisty | Get Started - Sign up"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
                canonicalUrl="https://restaurant.reisty.com"
      />
      <Navbar />
      <Box
        className="food_background"
        align="center"
        sx={{
          height: "270px",
          bgcolor: theme.palette.primary.main,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ color: "#fff", fontFamily: "outfit" }}
        >
          Request Info
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: "#fff", fontFamily: "outfit", fontSize: "13px" }}
        >
          Complete the form below, and a member of the Reisty team will promptly
          reach out to <br /> guide you through the next steps. We're excited to
          give you a tour!
        </Typography>
      </Box>

      <Box
        sx={{
          margin: "0 auto",
          width: { lg: "55%", md: "60%", sm: "80%", xs: "90%" },
          py: 5,
        }}
      >
        <Typography
          variant="body1"
          sx={{ textAlign: "center", fontSize: "13px" }}
        >
          To get in touch with our sales team and receive email marketing
          communications from Reisty, please complete the form provided below.
          Remember that you have the freedom to unsubscribe from Reisty emails
          whenever you wish.
        </Typography>
        <RequestForm />
      </Box>
      <Footer />

      </ThemeProvider>
    </>
  );
};

export default GetStarted;
