import React, { useEffect, useState } from "react";
import Header from "../../Components/Header";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  InputLabel,
  TextField,
  Autocomplete,
  ThemeProvider,
  Divider,
} from "@mui/material";

import { getOfferings } from "../../../axios/api";
import Loader from "../../Components/Common/Loader";
import OfferingEdit from "../../Components/Edits/Offerings/OfferingEdit";
import DiningEdit from "../../Components/Edits/Offerings/DiningEdits";
import SafetyEdit from "../../Components/Edits/Offerings/SafetyEdit";
import RestaurantExperienceEdit from "../../Components/Edits/Offerings/RestaurantExperienceEdit";
import { PiDotDuotone } from "react-icons/pi";
import { Helmet } from "react-helmet-async";
import SEO from "../../../Utility/SEO";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  borderRadius: "5px",
  boxShadow: 24,
  bgcolor: "#1a1a1a",
  overflow: "scroll",
  height: "60vh",
  pb: 3,
};

const BasicInformation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [offeringData, setOfferingData] = useState(null);

  const handleGetOfferings = async () => {
    setIsLoading(true);
    await getOfferings()
      .then((res) => {
        const { data } = res;

        setOfferingData(data?.result);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetOfferings();
  }, []);

  const Beverages = offeringData?.Beverages
    ? offeringData?.Beverages.map((option) => option.Name).join(", ")
    : "Empty";

  const Amenities = offeringData?.Amenities
    ? offeringData?.Amenities.map((option) => option.Description).join(", ")
    : "Empty";

  const dietaryOptions = offeringData?.DietaryOptions
    ? offeringData?.DietaryOptions?.map((option) => option.Name).join(", ")
    : "Empty";

  const SmokingOptions = offeringData?.SmokingOptions
    ? offeringData?.SmokingOptions?.map((option) => option.Description).join(
        ", "
      )
    : "Empty";

  const physicalDistancings = offeringData?.PhysicalDistancings
    ? offeringData?.PhysicalDistancings?.map(
        (option) => option.Description
      ).join(", ")
    : "Empty";

  const ProtectiveEquipments = offeringData?.ProtectiveEquipments
    ? offeringData?.ProtectiveEquipments?.map(
        (option) => option.Description
      ).join(", ")
    : "Empty";

  const SanitizationAndMaintainances = offeringData?.SanitizationAndMaintainances
    ? offeringData?.SanitizationAndMaintainances?.map((option) => (
        <React.Fragment key={option.Id}>
          <li style={{ lineHeight: "17px" }}> {option.Description}</li>
          <br />
        </React.Fragment>
      ))
    : "Empty";

  const Screenings = offeringData?.Screenings
    ? offeringData?.Screenings?.map((option) => (
        <React.Fragment key={option.Id}>
          <li style={{ lineHeight: "17px" }}> {option.Description}</li>
          <br />
        </React.Fragment>
      ))
    : "Empty";
  return (
    <>
      <SEO
        title="Reisty | Restaurant Offerings Management"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
          canonicalUrl="https://restaurant.reisty.com/dashboard/offerings"
      />
      {isLoading && <Loader />}
      <Header title="Offerings" description="Customize Dietary Options, Enhance Dining Experiences, Enforce Safety Precautions, and Set House Rules with Ease." />
      <Box
        sx={{
          margin: "0 auto",
          width: "95%",
          // height: "100%",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Box>
            <Typography
                variant="subtitle1"
              sx={{
      
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Offerings
            </Typography>
            <Typography
            variant="caption"
              sx={{
       
              }}
            >
              Tell us what makes your restuarant special.
            </Typography>
          </Box>
          <Box>
            <OfferingEdit data={offeringData} action={handleGetOfferings} />
          </Box>
        </Box>
        <Box sx={{ bgcolor: "background.card", borderRadius: "5px", mt: 1, px: 3, py: 3,  boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>
          <Grid
            container
            sx={{ width: { lg: "75%", md: "75%", sm: "100%", xs: "100%" } }}
            spacing={2}
          >
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Menu link:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                {offeringData?.MenuLink ? offeringData.MenuLink : "--"}
              </Typography>
            </Grid>
            {/* Restaurant Admin: */}
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Executive chef
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                {offeringData?.ExecutiveChef
                  ? offeringData?.ExecutiveChef
                  : "--"}
              </Typography>
            </Grid>
            {/* Restaurant Website: */}
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Dietary options
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                {dietaryOptions}
              </Typography>
            </Grid>

            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Beverages
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                {Beverages}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 6,
          }}
        >
          <Box>
            <Typography
          variant="subtitle1"
              sx={{
      
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Dining Experience
            </Typography>
            <Typography
         variant="caption"
            >
              Clarify the atmosphere and style of your establishment, providing
              guests with an idea of what to anticipate.
            </Typography>
          </Box>
          <Box>
            <DiningEdit data={offeringData} action={handleGetOfferings} />
          </Box>
        </Box>
        <Box sx={{ bgcolor: "background.card", borderRadius: "5px", mt: 1, px: 3, py: 3,  boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>
          <Grid
            container
            sx={{ width: { lg: "75%", md: "75%", sm: "100%", xs: "100%" } }}
            spacing={2}
          >
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                Restaurant Type:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                {offeringData?.RestaurantType}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                Parking Options:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                {offeringData?.ParkingOption}
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                Dress Code:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                {offeringData?.DressCode}
              </Typography>
            </Grid>
            {/* Restaurant Admin: */}
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                Amenities:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                {Amenities || ""}
              </Typography>
            </Grid>
            {/* Restaurant Website: */}
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                Smoking:
              </Typography>
            </Grid>
            <Grid item lg={9} md={9} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px",  }}>
                {SmokingOptions}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 6,
          }}
        >
          <Box>
            <Typography
             variant="subtitle1"
              sx={{
      
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Safety Precautions
            </Typography>
            <Typography
         variant="caption"
            >
              Inform guests about the health and safety protocols in place at
              your establishment.
            </Typography>
          </Box>
          <Box>
            <SafetyEdit data={offeringData} action={handleGetOfferings} />
          </Box>
        </Box>
        <Box sx={{ bgcolor: "background.card", borderRadius: "5px", mt: 1, px: 3, py: 3 , boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>
          <Grid
            container
            sx={{ width: { lg: "100%", md: "100%", sm: "100%", xs: "100%" } }}
            spacing={2}
          >
            <Grid item lg={3} md={4} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Sanitizing & Cleanliness
              </Typography>
            </Grid>
            <Grid item lg={9} md={8} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                {SanitizationAndMaintainances}
              </Typography>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Physical distancing
              </Typography>
            </Grid>
            <Grid item lg={9} md={8} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                {physicalDistancings}
              </Typography>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Protective equipment:
              </Typography>
            </Grid>
            <Grid item lg={9} md={8} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                {ProtectiveEquipments}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Screening
              </Typography>
            </Grid>
            <Grid item lg={9} md={8} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                {Screenings}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 4,
            pt: 3,
            borderTop: "1px  solid #ccc",
          }}
        >
          <Box>
            <Typography
                variant="subtitle1"
              sx={{
      
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Restaurant & Restauranteer Experience
            </Typography>
            {/* <Typography
              sx={{
                color: "#cccccc",
                fontSize: { lg: "14px", sm: "12px", xs: "11px" },
              }}
            >
              Inform guests about the health and safety protocols in place at
              your establishment.
            </Typography> */}
          </Box>
          <Box>
            <RestaurantExperienceEdit
              data={offeringData}
              action={handleGetOfferings}
            />
          </Box>
        </Box>
        <Box sx={{ bgcolor: "background.card", borderRadius: "5px", mt: 1, px: 3, py: 3, boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>
          <Grid
            container
            sx={{ width: { lg: "100%", md: "100%", sm: "100%", xs: "100%" } }}
            spacing={2}
          >
            <Grid item lg={3} md={4} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Deposit Per Person
              </Typography>
            </Grid>
            <Grid item lg={9} md={8} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                N{offeringData?.Experience?.
DepositPerPerson?.toLocaleString()  || "--"}
              </Typography>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
              House Rules
              </Typography>
            </Grid>
            <Grid item lg={9} md={8} sm={6} xs={6}>

                        <ReactQuill
      value={offeringData?.Experience?.HouseRules}
      readOnly={true}
      theme={null} // Disable theme
      modules={{ toolbar: false }}
      style={{fontFamily:'outfit', marginTop:'-10px', padding:'0%', }}
      className="ql-editor" // Apply custom CSS class
    />
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
                Cancellation policy
              </Typography>
            </Grid>
            <Grid item lg={9} md={8} sm={6} xs={6}>
              <Typography sx={{ fontSize: "14px", }}>
          {offeringData?.Experience?.CancellationPolicy || "--"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default BasicInformation;
