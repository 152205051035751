import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Modal,
  Grid,
  TextField,
  InputLabel,
  InputAdornment,
  RadioGroup,
  MenuItem,
  Avatar,
  ThemeProvider,
  createTheme,
  LinearProgress,
  Checkbox,
} from "@mui/material";
import walk from "../../../assets/Icons/walk.svg";
import { alpha, styled } from "@mui/material/styles";
import dp from "../../../assets/Icons/dp.svg";
import { ArrowBack, CloseOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  createWalkin,
  searchGuestBook,
  createGuestBook,
  getOpeningHours,
} from "../../../axios/api";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector } from "react-redux";
import CustomRadio from "../CustomField/CustomRadio";
import CustomTextField from "../CustomField/CustomTextField";
import Loader from "../Common/Loader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CustomInput from "../CustomField/CustomInput";

const CssTextField = styled(TextField)({
  height: "30px",
  color: "#fff",
  fontWeight: 500,
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#333",
    color: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#333",
      color: "#fff",
      fontWeight: 500,
    },
    "&:hover fieldset": {
      borderColor: "#333",
      color: "#fff",
      fontWeight: 500,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#333",
      color: "#fff",
      fontWeight: 500,
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 600, md: 600, sm: 350, xs: 350 },
  bgcolor: "#1a1a1a",
  boxShadow: 24,
  p: 0,
  borderRadius: "10px",
  // zIndex: -1,
};

const NewWalkins = ({action}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searchParams, setSearchParams] = useState("");
  const [data, setData] = useState(null);
  const [isCard, setIsCard] = useState(false);
  const [cardData, setCardData] = useState(null);
  const restaurantList = useSelector(
    (state) => state?.restaurants?.data?.result
  );
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(null);
  const [guestId, setGuestId] = useState("");

  const [restaurantId, setRestaurantId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [partySize, setPartySize] = useState(1);
  const [isAdd, setIsAdd] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("Nigeria");
  const [dob, setDob] = useState("");
  const [weddingAnniversary, setWeddingAnniversary] = useState("");
  const [graduationAnniversary, setgraduationAnniversary] = useState("");
  const [isTimeLoading, setTimeLoading] = useState(false);
  const [timeError, setTimeError] = useState(null);
  const [timeList, setTimeList] = useState(null);

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };
  const handleSearch = async () => {
    setIsLoading(true);
    await searchGuestBook(searchParams)
      .then((res) => {
        setIsLoading(false);
        setData(res?.data?.result);

      })
      .catch((err) => {
        setIsLoading(false);
      });
  };


  const handleCreateGuest = async () => {
    setIsLoading(true);

    await createGuestBook(
      firstName,
      lastName,
      phone,
      email,
      country,
      dob,
      graduationAnniversary,
      weddingAnniversary
    )
      .then((res) => {
        setIsLoading(false);
        const { data } = res;
        if (data?.status) {
          handleAlert("success", `${data?.success_message}`);
          // navigate("/dashboard/guest-book");
          setIsAdd(false);
          handleSearch();
        } else {
          handleAlert("error", `${data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  useEffect(() => {
    if (searchParams.length >= 3) {
      handleSearch();
    }
  }, [searchParams]);

  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleClick = (event) => {
    setSearchParams(event.target.value);
    if (searchParams.length > 1) {
      setAnchorEl(true);
    } else setAnchorEl(false);
  };

  const openPoper = Boolean(anchorEl);
  const id = openPoper ? "simple-popper" : undefined;

  const handleDateChange = (newDate) => {
    // Format the date to "YYYY-MM-DD" and set it in the state
    setDate(newDate ? dayjs(newDate).format("YYYY-MM-DD") : null);
  };
  const formattedTime = time ? dayjs(time).format("HH:mm") : "";

  const handleWalkin = async () => {
    setLoading(true);

    await createWalkin(restaurantId, date, time, guestId, partySize)
      .then((res) => {
        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
          handleClose();
          action();
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  useEffect(() => {
    if (restaurantId && date && partySize) {
      handleGetHours();
    }
  }, [restaurantId, date, partySize]);

  const handleGetHours = async () => {
    setTimeLoading(true);
    setTimeList(null);
    setTimeError(null);

    await getOpeningHours(restaurantId, date, partySize)
      .then((res) => {
        setTimeLoading(false);

        if (res?.data?.status) {
          setTimeList(res?.data?.result[0]?.GroupedTime[0]);
        } else {
          setTimeError(res?.data?.error_message);
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setTimeLoading(false);

        handleAlert("error", `${err}`);
      });
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        sx={{
          cursor: "pointer",
          p: 0,
          bgcolor: "#333",
          border: "0.5px solid #fff",
          display: "flex",
          alignItems: "center",
          borderRadius: "8px",
          px: 3,
        }}
      >
        <IconButton>
          <img src={walk}  alt="walk_icon"/>
        </IconButton>
        <Typography sx={{ color: "#fff", fontSize: "11px", fontWeight: 500 }}>
          Wak-ins
        </Typography>
        <IconButton></IconButton>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loading && <Loader />}
          <Box
            sx={{
              py: 2,
              px: { lg: 3, md: 3, sm: 2, xs: 2 },
              borderBottom: "1px solid #BC172F",
              bgcolor: "#333",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            <Grid container sx={{ display: "flex", alignItems: "center" }}>
              <Grid item md={2} xs={2}>
                {isAdd && (
                  <IconButton onClick={() => setIsAdd(!isAdd)}>
                    <ArrowBack sx={{ fontSize: "16px" }} />
                  </IconButton>
                )}
              </Grid>
              <Grid item md={8} xs={8}>
                <Typography
                  sx={{ color: "#fff", textAlign: "center", fontWeight: 500 }}
                >
                  New Walk-in
                </Typography>
              </Grid>
              <Grid item md={2} xs={2} align="right">
                <IconButton onClick={handleClose}>
                  <CloseOutlined sx={{ color: "#fff" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          {isAdd ? (
            <>
              <Box
                sx={{
                  maxHeight: "72vh",
                  overflow: "scroll",
                  bgcolor: "#1a1a1a",
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                <Box sx={{ mt: 3, px: { lg: 3, md: 3, sm: 2, xs: 2 } }}>
                  <CustomTextField
                    name="Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <Box sx={{ mt: 2 }}>
                    <CustomTextField
                      name="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <CustomTextField
                      name="Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: "14px",
                        fontFamily: "outfit",
                      }}
                    >
                      Party Size
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <IconButton
                        sx={{ p: 2, bgcolor: "#333", borderRadius: 2 }}
                        onClick={() => {
                          if (partySize > 1) {
                            setPartySize(partySize - 1);
                          }
                        }}
                      >
                        <RemoveIcon sx={{ color: "#fff" }} />
                      </IconButton>
                      <Box
                        sx={{
                          p: 2,
                          bgcolor: "#333",
                          borderRadius: 2,
                          width: { lg: "70%", md: "70%", sm: "50%", xs: "50%" },
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        {partySize}
                      </Box>
                      <IconButton
                        sx={{ p: 2, bgcolor: "#333", borderRadius: 2 }}
                        onClick={() => setPartySize(partySize + 1)}
                      >
                        <AddIcon sx={{ color: "#fff" }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item md={6} xs={12}>
                        <InputLabel
                          sx={{ color: "#fff", fontWeight: 500, mb: 0.5 }}
                        >
                          Time
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            // disablePast
                            label="Time"
                            value={time}
                            onChange={(newValue) => setTime(newValue)}
                            sx={{
                              fontFamily: "outfit",
                              fontSize: "13px",
                              borderRadius: "10px",
                              offset: " 1px solid #ccc",
                              fontWeight: 500,
                              color: "#ccc",
                              border: "1px solid #ccc",
                              width: "100%",
                              // Replace with your desired font family
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <InputLabel
                          sx={{ color: "#fff", fontWeight: 500, mb: 0.5 }}
                        >
                          Date
                        </InputLabel>
                        <CustomInput
                          value={date}
                          size="large"
                          onChange={(e) => setDate(e.target.value)}
                          type="date"
                        />
                      </Grid>
                    </Grid>

                    <Box sx={{ mt: 2 }}>
                      <Checkbox />{" "}
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        Diner info not provided
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "80px",
                  bgcolor: "#333",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                  mt: 2,
                  px: { lg: 3, md: 3, sm: 2, xs: 2 },
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "initial",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: 500,
                    borderRadius: "5px",
                  }}
                >
                  Add Walk-In
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ maxHeight: "72vh", overflow: "scroll" }}>
                {!isCard ? (
                  <>
                    <Box
                      sx={{ p: { lg: 4, md: 4, sm: 2, xs: 2 } }}
                      aria-describedby={id}
                    >
                      <Box align="right">
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "initial",
                            fontWeight: 500,
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "12px",
                            },
                          }}
                          onClick={() => setIsAdd(true)}
                        >
                          Add New Walk-in
                        </Button>
                      </Box>
                      <InputLabel
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        Search guest information
                      </InputLabel>

                      <TextField
                        fullWidth
                        value={searchParams}
                        onChange={handleClick}
                        aria-describedby={id}
                        placeholder="Enter name, phone or email address"
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <SearchIcon sx={{ color: "#fff" }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  setAnchorEl(false);
                                  setSearchParams("");
                                }}
                              >
                                <CloseOutlined sx={{ color: "#fff" }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            fontFamily: "outfit",
                            fontSize: "13px",
                            borderRadius: "10px",
                            border: "1px solid #fff",
                            color: "#fff",
                            offset: " 1px solid #fff",
                          },
                        }}
                      />

                      {anchorEl && (
                        <>
                          <Box
                            sx={{
                              bgcolor: "#333",
                              borderRadius: 2,
                              maxHeight: "300px",
                              overflow: "scroll",
                            }}
                          >
                            {!data || data.length === 0 ? (
                              <>
                                <MenuItem
                                  onClick={() => {
                                    setFirstName(searchParams);
                                    setIsAdd(true);
                                  }}
                                  sx={{
                                    borderBottom: "1px solid #BC172F",
                                    py: 1,
                                    px: 2,
                                    display: "flex",
                                    columnGap: 3,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      bgcolor: "#555",
                                      display: "grid",
                                      placeItems: "center",
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: 1,
                                    }}
                                  >
                                    <AddIcon sx={{ color: "#fff" }} />
                                  </Box>
                                  <Typography
                                    sx={{
                                      color: "#fff",
                                      fontFamily: "outfit",
                                      fontSize: {
                                        lg: "14px",
                                        md: "14px",
                                        sm: "12px",
                                        xs: "10px",
                                      },
                                      fontWeight: 400,
                                    }}
                                  >{`Add "${searchParams}" to guestbook`}</Typography>
                                </MenuItem>
                                {isLoading && <LinearProgress />}

                                <Box sx={{ p: 2 }}>
                                  <Typography
                                    sx={{
                                      color: "#fff",
                                      fontFamily: "outfit",
                                      fontSize: "12px",
                                      fontWeight: 500,
                                      textAlign: "center",
                                    }}
                                  >
                                    {isLoading
                                      ? "Searching..."
                                      : "Guest Not Found"}
                                  </Typography>
                                </Box>
                              </>
                            ) : (
                              <>
                                <Link to="/dashboard/guest-book">
                                  <MenuItem
                                    sx={{
                                      borderBottom: "1px solid #BC172F",
                                      py: 1,
                                      px: 2,
                                      display: "flex",
                                      columnGap: 3,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        bgcolor: "#555",
                                        display: "grid",
                                        placeItems: "center",
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: 1,
                                        py: 2,
                                      }}
                                    >
                                      <AddIcon sx={{ color: "#fff" }} />
                                    </Box>
                                    <Typography
                                      sx={{
                                        color: "#fff",
                                        fontFamily: "outfit",
                                        fontSize: {
                                          lg: "14px",
                                          md: "14px",
                                          sm: "12px",
                                          xs: "10px",
                                        },
                                        fontWeight: 400,
                                      }}
                                    >{`Add "${searchParams}" to guestbook`}</Typography>
                                  </MenuItem>
                                </Link>
                                {isLoading && <LinearProgress />}
                                {data?.map((item, index) => (
                                  <MenuItem
                                    sx={{ columnGap: 2, py: 1 }}
                                    key={index}
                                    onClick={() => {
                                      setIsCard(true);
                                      setCardData(item);
                                      setGuestId(item.Id);
                                    }}
                                  >
                                    <Avatar />
                                    <Box>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: {
                                            lg: "14px",
                                            md: "14px",
                                            sm: "12px",
                                            xs: "10px",
                                          },
                                          fontFamily: "outfit",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {`${item?.FirstName} ${item?.LastName}`}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: "10px",
                                          fontFamily: "outfit",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {item?.EmailAddress}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          color: "#fff",
                                          fontSize: {
                                            lg: "10px",
                                            md: "10px",
                                            sm: "8px",
                                            xs: "8px",
                                          },
                                          fontFamily: "outfit",
                                          fontWeight: 300,
                                        }}
                                      >
                                        {item?.PhoneNumber}
                                      </Typography>
                                    </Box>
                                  </MenuItem>
                                ))}
                              </>
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ p: 4 }}>
                      <Box align="center">
                        <IconButton onClick={() => setIsCard(false)}>
                          <CloseOutlined />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: "#333",
                          height: {
                            lg: "200px",
                            md: "200px",
                            sm: "150px",
                            xs: "150px",
                          },
                          border: "1px solid #fff",
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Avatar />
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              lg: "12px",
                              md: "12px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontFamily: "outfit",
                            fontWeight: 500,
                            mt: 2,
                          }}
                        >
                          {`${cardData?.FirstName} ${cardData?.LastName}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "10px",
                            fontFamily: "outfit",
                            fontWeight: 500,
                            mt: 0.5,
                          }}
                        >
                          {cardData?.EmailAddress}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "10px",
                            fontFamily: "outfit",
                            fontWeight: 300,
                            mt: 0.5,
                          }}
                        >
                          {cardData?.PhoneNumber}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 3 }}>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontWeight: 500,
                            fontSize: {
                              lg: "14px",
                              md: "14px",
                              sm: "12px",
                              xs: "10px",
                            },
                            fontFamily: "outfit",
                          }}
                        >
                          Party Size
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 1,
                          }}
                        >
                          <IconButton
                            disabled={partySize < 2}
                            sx={{ p: 1.5, bgcolor: "#333", borderRadius: 2 }}
                            onClick={() => {
                              if (partySize > 1) {
                                setPartySize(partySize - 1);
                              }
                            }}
                          >
                            <RemoveIcon sx={{ color: "#fff" }} />
                          </IconButton>
                          <Box
                            sx={{
                              p: 2,
                              bgcolor: "#333",
                              borderRadius: 2,
                              width: {
                                lg: "70%",
                                md: "70%",
                                sm: "50%",
                                xs: "50%",
                              },
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {partySize}
                          </Box>
                          <IconButton
                            sx={{ p: 1.5, bgcolor: "#333", borderRadius: 2 }}
                            onClick={() => setPartySize(partySize + 1)}
                          >
                            <AddIcon sx={{ color: "#fff" }} />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        {/* <Typography
                          sx={{
                            color: "#fff",
                            fontWeight: 500,
                            fontSize: "14px",
                            fontFamily: "outfit",
                          }}
                        >
                          Experiences
                        </Typography> */}
                        {/* <Box
                          className="show_scrollbar"
                          sx={{
                            mt: 1,
                            py: 2,
                            maxHeight: "150px",
                            overflow: "scroll",
                          }}
                        >
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                          >
                            <Box
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "auto auto auto",
                              }}
                            >
                              {restaurantList.map((item, index) => (
                                <CustomRadio
                                  label={item.Name}
                                  onChange={(e) => setRestaurantId(item.Id)}
                                />
                              ))}
                            </Box>
                          </RadioGroup>
                        </Box> */}

                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          <Grid item md={6} xs={12}>
                            <InputLabel
                              sx={{
                                color: "#fff",
                                fontWeight: 500,
                                mb: 0.5,
                                fontSize: {
                                  lg: "14px",
                                  md: "14px",
                                  sm: "12px",
                                  xs: "10px",
                                },
                              }}
                            >
                              Time
                            </InputLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                // disablePast

                                value={time}
                                onChange={(newValue) => setTime(newValue)}
                                sx={{
                                  fontFamily: "outfit",
                                  fontSize: {
                                    lg: "13px",
                                    md: "13px",
                                    sm: "12px",
                                    xs: "10px",
                                  },
                                  borderRadius: "10px",
                                  offset: " 1px solid #ccc",
                                  fontWeight: 500,
                                  color: "#ccc",
                                  border: "1px solid #ccc",
                                  width: "100%",
                                  // Replace with your desired font family
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <InputLabel
                              sx={{
                                color: "#fff",
                                fontWeight: 500,
                                mb: 0.5,
                                fontSize: {
                                  lg: "14px",
                                  md: "14px",
                                  sm: "12px",
                                  xs: "10px",
                                },
                              }}
                            >
                              Date
                            </InputLabel>
                            <CustomInput
                              value={date}
                              size="large"
                              onChange={(e) => setDate(e.target.value)}
                              type="date"
                            />
                          </Grid>
                          {/* <Grid item md={12}>
                            <InputLabel
                              sx={{ color: "#fff", fontWeight: 500, mb: 0.5 }}
                            >
                              Time
                            </InputLabel>
                            {isTimeLoading ? (
                              <>
                                <Box sx={{ mt: 1 }}>
                                  <LinearProgress />
                                  <Typography
                                    sx={{
                                      mt: 2,
                                      textAlign: "center",
                                      color: "#ccc",
                                    }}
                                  >
                                    Getting available time...{" "}
                                  </Typography>
                                </Box>
                              </>
                            ) : (
                              <>
                                {timeError && (
                                  <Typography color="primary" sx={{}}>
                                    {timeError}
                                  </Typography>
                                )}
                                {timeList && (
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                      mt: 2,
                                      maxHeight: "200px",
                                      overflow: "scroll",
                                    }}
                                  >
                                    {timeList?.map((item, index) => (
                                      <Grid item md={3} key={index}>
                                        <Box
                                          onClick={() => setTime(item?.Time)}
                                          sx={{
                                            bgcolor: "#333",
                                            height: "50px",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            display: "grid",
                                            placeItems: "center",
                                            transition: "0.2s all linear",
                                            ...(time === item?.Time && {
                                              bgcolor: "#BC172F",
                                            }),
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              color: "#fff",
                                              fontWeight: 500,
                                              textAlign: "center",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {item?.Time}
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    ))}
                                  </Grid>
                                )}
                              </>
                            )}
                          </Grid> */}
                        </Grid>
                      </Box>
                    </Box>
                    <Box
                      align="right"
                      sx={{
                        bgcolor: "#333",
                        py: 2,
                        px: 3,
                        borderTop: "1px solid #fff",
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{ textTransform: "initial", fontFamily: "outfit" }}
                        onClick={handleWalkin}
                      >
                        Add Walk-in
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default NewWalkins;
