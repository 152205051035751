import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import calender from "../../../assets/Icons/calendar.svg";
import profileIcon from "../../../assets/Icons/user-square.svg";
import clock from "../../../assets/Icons/clock.svg";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import { deleteGuest, getGuestAnalysis } from "../../../axios/api";
import Loader from "../Common/Loader";
import { useSnackbar } from "notistack";
import { IndeterminateCheckBoxRounded } from "@mui/icons-material";
import UpgradeOverlay from "../Common/UpgradeOverlay";
import DeleteGuest from "./AdvancedGuest/DeleteGuest";

import { CgProfile } from "react-icons/cg";

import { AiOutlineClockCircle } from "react-icons/ai";

const GuestList = ({ data, action }) => {
  const [selected, setSelected] = useState(null);
  const [outletData, setOutletData] = useState(null);
  const [alignment, setAlignment] = React.useState("left");
  const [selectedButton, setSelectedButton] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleSelect = (index, data) => {
    setSelected(index);
    setOutletData(data);
  };

  const handleGetAnalysis = async () => {
    setIsLoading(true);
    await getGuestAnalysis(outletData?.Id)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          setData(res?.data?.result[0]);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err?.message}`);
      });
  };
  useEffect(() => {
    handleGetAnalysis();
  }, [outletData]);

  const handleDeleteGuest = async () => {
    setIsLoading(true);

    await deleteGuest(outletData?.Id)
      .then((res) => {
        setIsLoading(false);

        if (res?.data?.status) {
          handleAlert("success", `${res?.data?.success_message}`);
          action();
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleAlert("error", `${err}`);
      });
  };



  const [searchParams,  setSearchParams] = useState("")

  const [filteredData, setFilteredData]  = useState(data)

    const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    // Filter items based on the search term
    const filteredItems = data?.filter(
      (item) => item?.FirstName.toLowerCase().includes(searchTerm) || item?.LastName.toLowerCase().includes(searchTerm) || item?.EmailAddress.toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredItems);
    setSearchParams(searchTerm);
  };

  useEffect(()=>{
    setFilteredData(data)
  },[data])

  return (
    <>
      {isLoading && <Loader />}
      <Grid container sx={{ display: "flex", }}>
        <Grid item lg={3} md={4} sx={{}}>
          <Box
            sx={{
              borderRight: "0.5px solid ",
              borderColor:'text.primary',
              height: "85vh",
              overflow: "scroll",
            }}
          >
            <Box
              sx={{
                p: 2,
                bgcolor: "background.card",
                borderBottom: "0.5px solid #fff",
              }}
            >
              <TextField
                 value={searchParams}  onChange={handleSearch}
                size="small"
                fullWidth
                placeholder="Search contact, email or phone no"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "text.primary" }} />
                    </InputAdornment>
                  ),
                  style: {
                    fontFamily: "outfit",
                    fontSize: "13px",
                    borderRadius: "10px",
              
                    fontWeight: 500,
               
                    background: "backgroud.default",

                    // Replace with your desired font family
                  },
                }}
              />
            </Box>
            <Box sx={{ bgcolor: "background.card", p: 1, px: 2, boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>
              <Typography
                sx={{  fontSize: "12px", fontWeight: 500 }}
              >
                   All available guests
              </Typography>
            </Box>
            <Box>
              <List sx={{ mt: 1 }}>
                {data &&
                  filteredData.slice(0, 2).map((item, index) => (
                    <ListItemButton
                      selected={index === selected}
                      key={index}
                      onClick={() => handleSelect(index, item)}
                      sx={{
                        "&.Mui-selected": { background: "background.paper" },
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          columnGap: 3,
                        }}
                      >
                        <Avatar
                          sx={{ width: "40px", height: "40px", color:'text.primary' }}
                        >{`${item.FirstName?.charAt(0)}${item.LastName?.charAt(
                          0
                        )}`}</Avatar>
                        <Box>
                          <Typography
                            sx={{
                          
                              fontSize: "12px",
                              fontWeight: 500,
                          
                            }}
                          >
                            {`${item?.FirstName} ${item?.LastName}`}
                          </Typography>
                          <Typography
                            sx={{
                          
                              fontSize: "10px",
                              fontWeight: 400,
                              mt: -0.5,
                            }}
                          >
                            {item?.EmailAddress}
                          </Typography>
                          <Typography
                            sx={{
                          
                              fontSize: "10px",
                              fontWeight: 300,
                              mt: 0.4,
                            }}
                          >
                            {item?.PhoneNumber}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                  ))}
<UpgradeOverlay small>
                {data &&
                  filteredData?.slice(2, filteredData?.length).map((item, index) => (

                    <ListItemButton
                      selected={index === selected}
                      key={index}
                      onClick={() => handleSelect(index, item)}
                      sx={{
                        "&.Mui-selected": { background: "background.paper" },
                        cursor: "pointer",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          // justifyContent: "space-between",
                          columnGap: 3,
                        }}
                      >
                        <Avatar
                          sx={{ width: "40px", height: "40px" , color:'text.primary'}}
                        >{`${item.FirstName?.charAt(0)}${item.LastName?.charAt(
                          0
                        )}`}</Avatar>
                        <Box>
                          <Typography
                            sx={{
                     
                              fontSize: "12px",
                              fontWeight: 500,
                          
                            }}
                          >
                            {`${item?.FirstName} ${item?.LastName}`}
                          </Typography>
                          <Typography
                            sx={{
                     
                              fontSize: "10px",
                              fontWeight: 400,
                              mt: -0.5,
                            }}
                          >
                            {item?.EmailAddress}
                          </Typography>
                          <Typography
                            sx={{
                     
                              fontSize: "10px",
                              fontWeight: 300,
                              mt: 0.4,
                            }}
                          >
                            {item?.PhoneNumber}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                  ))}
                  </UpgradeOverlay>
              </List>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={9} md={8} sx={{ height: "85vh", overflow: "scroll" }}>
          {!outletData ? (
            <>
              <Box sx={{ display: "grid", placeItems: "center", height:'100%' }}>
                <Typography sx={{ fontWeight:500}}>
                  No guest selected{" "}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{}}>
                <Box
                  sx={{
                    px: 4,
                    py: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "0.3px solid",
                    borderColor:'text.primary'
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 500, fontSize: "14px" , fontFamily:'optima'}}
                  >{`${datas?.FirstName} ${datas?.LastName  || ""}`}</Typography>
                  <Box sx={{ pr: 3 }}>
                    <IconButton
                      onClick={() => setSelectedButton(1)}
                      sx={{
                        borderRight: "0.5px solid ",
                        borderLeft: "0.5px solid ",
                        borderRadius: 0,
                        p: 2,
                            borderColor:'text.primary',
                        "&:hover": {
                           background: "background.paper",
                          color:'primary.main'
                        },
                        ...(selectedButton === 1 && {
                          background: "background.paper",
                          color:'primary.main'
                        }),
                      }}
                    >
                   <CgProfile style={{fontSize:'20px'}} />
                    </IconButton>
                    <IconButton
                      onClick={() => setSelectedButton(2)}
                      sx={{
                        borderRight: "0.5px solid",
                        borderRadius: 0,
                        p: 2,
                            borderColor:'text.primary',
                        "&:hover": {
                          background: "background.paper",
                          color:'primary.main'
                        },
                        ...(selectedButton === 2 && {
                         background: "background.paper",
                          color:'primary.main'
                        }),
                      }}
                    >
              <AiOutlineClockCircle style={{fontSize:'20px'}} />
                    </IconButton>
                  </Box>
                </Box>
                {selectedButton === 1 ? (
                  <>
                    <Box sx={{ mt: 10, px: 4 }}>
                      <Typography sx={{  fontWeight: 500 }}>
                        Contact Information
                      </Typography>
                      <Box
                        sx={{
                          mt: 2,
                          bgcolor: "#1a1a1a",
                          borderRadius: 2,
                          px: 0,
                          py: 2,
                           boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            bgcolor: "background.card",
                            borderRadius:'8px'
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            borderBottom: "0.5px solid #fff",
                            px: 3,
                            py: 2,
                          }}
                        >
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              First Name
                            </Typography>
                          </Grid>
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >{`${datas?.FirstName || "Not provided"} `}</Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            borderBottom: "0.5px solid #fff",
                            px: 3,
                            py: 2,
                          }}
                        >
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              Last Name
                            </Typography>
                          </Grid>
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >{`${datas?.LastName || "Not provided"}`}</Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            borderBottom: "0.5px solid #fff",
                            px: 3,
                            py: 2,
                          }}
                        >
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              Phone Number
                            </Typography>
                          </Grid>
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >{`${datas?.PhoneNumber || "Not provided"}`}</Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            borderBottom: "0.5px solid #fff",
                            px: 3,
                            py: 2,
                          }}
                        >
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              Email Address
                            </Typography>
                          </Grid>
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >{`${datas?.EmailAddress || "Not provided"}`}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 5, px: 4 }}>
                      <Typography sx={{  fontWeight: 500 }}>
                        Notable Dates
                      </Typography>
                      <Box
                        sx={{
                          mt: 2,
                       
                          px: 0,
                          py: 2,
                                  boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            bgcolor: "background.card",
                            borderRadius:'8px'
                        }}
                      >
                        <Grid
                          container
                          sx={{
                            borderBottom: "0.5px solid #fff",
                            px: 3,
                            py: 2,
                          }}
                        >
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              Birthday
                            </Typography>
                          </Grid>
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >{`${
                              datas?.Birthday ? datas?.Birthday : "No Birthday"
                            }`}</Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            borderBottom: "0.5px solid #fff",
                            px: 3,
                            py: 2,
                          }}
                        >
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              Anniversary
                            </Typography>
                          </Grid>
                          <Grid item lg={6}>
                            <Typography
                              sx={{
                           
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >{`${
                              datas?.WeddingAnniversary
                                ? datas?.WeddingAnniversary
                                : "No Anniversary"
                            }`}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ mt: 6, px: 4 }}>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                       
                          }}
                        >
                          Visit History
                        </Typography>
                        <Box sx={{ mt: 4 }}>
                          <Table
                            sx={{        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            bgcolor: "background.card",
                            borderRadius:'8px' }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontWeight: 500,
                               
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                    borderBottom: "0.5px solid #fff",
                                  }}
                                >
                                  Upcoming
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontWeight: 500,
                               
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                    borderBottom: "0.5px solid #fff",
                                  }}
                                >
                                  Visit
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontWeight: 500,
                               
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                    borderBottom: "0.5px solid #fff",
                                  }}
                                >
                                  Cancellations
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontWeight: 500,
                               
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                    borderBottom: "0.5px solid #fff",
                                  }}
                                >
                                  No shows
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontWeight: 500,
                               
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                    border: "none",
                                  }}
                                >
                                  {datas?.UpcomingBookings
                                    ? datas?.UpcomingBookings
                                    : 0}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontWeight: 500,
                               
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                    border: "none",
                                  }}
                                >
                                  {
                                    datas?.BookingAttendance
                                  }
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontWeight: 500,
                               
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                    border: "none",
                                  }}
                                >
                                  {datas?.CancelledBookings
                                    ? datas?.CancelledBookings
                                    : 0}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontWeight: 500,
                               
                                    fontSize: "14px",
                                    fontFamily: "outfit",
                                    border: "none",
                                  }}
                                >
                                  {datas?.
NoShowForBookings
                                    ? datas?.
NoShowForBookings
                                    : 0}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>

                      {/* <Box sx={{ mt: 5 }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#fff",
                          }}
                        >
                          Upcoming
                        </Typography>
                        <Box
                          sx={{
                            mt: 3,
                            p: 4,
                            bgcolor: "#1a1a1a",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#fff",
                              }}
                            >
                              Thursday, April 30, 2023
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#fff",
                                mt: 2,
                              }}
                            >
                              Orchid Bistro Premium
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#fff",
                                mt: 2,
                              }}
                            >
                              5 Guest
                            </Typography>
                            <Button
                              variant="outlined"
                              disabled
                              sx={{
                                mt: 3,
                                color: "#fff",
                                "&:disabled": {
                                  border: "0.5px solid #fff",
                                  color: "#fff",
                                  bgcolor: "#555",
                                  borderRadius: "5px",
                                },
                              }}
                            >
                              Booked
                            </Button>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#fff",
                                mt: 2,
                              }}
                            >
                              11:30 PM
                            </Typography>
                          </Box>
                        </Box>
                      </Box> */}
                      <Box sx={{ mt: 5 }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                        
                          }}
                        >
                          History
                        </Typography>

                        {!datas?.guestReservationHitory ||
                        datas?.guestReservationHitory.length === 0 ? (
                          <>
                            <Box
                              sx={{
                                mt: 3,
                                p: 4,
                         
                                display: "flex",
                                justifyContent: "space-between",
                                        boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            bgcolor: "background.card",
                            borderRadius:'8px'
                              }}
                            >
                              <Typography
                                sx={{ color: "#ccc", fontWeight: 400 }}
                              >
                                No History Records
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <>
                            {datas?.guestReservationHitory?.map(
                              (item, index) => (
                                <>
                                  <Box
                                    sx={{
                                      mt: 3,
                                      p: 4,
                                 
                                      display: "flex",
                                      justifyContent: "space-between",
                                      mb: 2,
                                              boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                            bgcolor: "background.card",
                            borderRadius:'8px'
                                    }}
                                  >
                                    <Box key={index} sx={{}}>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          fontWeight: 500,
                                     
                                        }}
                                      >
                                        {formatDate(item?.Date)}
                                      </Typography>
                                      {/* <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                           
                                mt: 2,
                              }}
                            >
                              Orchid Bistro Premium
                            </Typography> */}
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: 500,
                                     
                                          mt: 2,
                                        }}
                                      >
                                        {item?.Time}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: 500,
                                     
                                          mt: 2,
                                        }}
                                      >
                                        {item?.PartySize} Guest
                                      </Typography>
                                      <Button
                                        variant="outlined"
                                        disabled
                                        sx={{
                                          mt: 3,
                                     
                                          "&:disabled": {
                                            border: "0.5px solid #fff",
                                       color:'#fff',
                                            bgcolor: "#555",
                                            borderRadius: "5px",
                                          },
                                        }}
                                      >
                                        {item?.Status}
                                      </Button>
                                    </Box>
                                    <Box>
                                      {/* <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                           
                                mt: 2,
                              }}
                            >
                          {item?.Time}
                            </Typography> */}
                                    </Box>
                                  </Box>
                                </>
                              )
                            )}
                          </>
                        )}
                      </Box>

                      <Box sx={{ my: 5 }} align="right">
                
                        <DeleteGuest action={handleGetAnalysis}  id={datas?.Id} />
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default GuestList;

function formatDate(dateString) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  const dayOfWeek = days[date.getDay()];

  return `${dayOfWeek}, ${month} ${day}, ${year}`;
}
