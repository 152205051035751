import "./STYLES/main.css";
import { Routes } from "./Routes";
import ScrollToTop from "./ScrollToTop";
import { SnackbarProvider } from "notistack";
import { useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { AnimatePresence } from "framer-motion";
import ErrorAlert from "./Dashboard/Components/Common/ErrorAlert";
import SuccessAlert from "./Dashboard/Components/Common/SuccessAlert";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { NetworkStatusIndicator } from "./Utility/NetworkStatusIndicator";
import { TouchBackend } from "react-dnd-touch-backend";

import { HelmetProvider } from "react-helmet-async";
import RefreshToken from "./Utility/RefreshToken";
import { Avatar, useMediaQuery, useTheme } from "@mui/material";
function App() {
  const location = useLocation();
  const helmetContext = {};

  const isTouchDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  };

  const touchBackendOptions = { enableMouseEvents: true };

  return (
    <>
      <NetworkStatusIndicator />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            Components={{ error: ErrorAlert, success: SuccessAlert }}
            maxSnack={3}
          >
            <ScrollToTop />
            <HelmetProvider context={helmetContext}>
              <DndProvider
                backend={
                  isTouchDevice()
                    ?TouchBackend
                    : HTML5Backend
                }
              >
                <RefreshToken />
                <Routes />
              </DndProvider>
            </HelmetProvider>
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
