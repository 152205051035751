import React from "react";
import Lottie from "react-lottie";
import emptyAnime from "../../assets/anime/Wu7uTXe2EN.json";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SEO from "../../Utility/SEO";
const NotFound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: emptyAnime,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <SEO
        title="Reisty | Page Not Found"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
          bgcolor: "#000",
        }}
      >
        <Lottie options={defaultOptions} width={400} height={200} />
        <Box align="center">
          <Link to="/">
            <Button variant="contained" sx={{ mt: 4 }}>
              Go Home{" "}
            </Button>
          </Link>
          <Link to="/dashboard">
            <Button variant="contained" sx={{ mt: 4, ml: 4 }}>
              Go to Dashboard{" "}
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default NotFound;
