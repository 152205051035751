import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  ListItemButton,
  InputLabel,
  TextField,
  Switch,
  InputAdornment,
  IconButton,
} from "@mui/material";
// import { getProflie } from "../../../api";
import Loader from "../../Components/Common/Loader";
import { getEvent } from "../../../axios/api";
import { useSnackbar } from "notistack";
import { MdOutlineDone } from "react-icons/md";
import { LiaCopy } from "react-icons/lia";
import { Helmet } from "react-helmet-async";
import SEO from "../../../Utility/SEO";
import Header from "../../Components/Header";

const OnlineReservation = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedRestaurant = localStorage.getItem("restaurantID");
  const selectedRestaurantName = localStorage.getItem("restaurantName");
  const { enqueueSnackbar } = useSnackbar();
  const [copied, setCopied] = useState(false);

  //   const handleGetProfile = async () => {
  //   setIsLoading(true);
  //   await getProflie()
  //     .then((res) => {
  //       setIsLoading(false);
  //       setData(res?.data?.data);

  //     })
  //     .catch((err) => {

  //       setIsLoading(false);
  //     });
  // };
  //   useEffect(() => {
  //   handleGetProfile();
  // }, []);

  const handleCopy = (url) => {
    navigator.clipboard
      .writeText(url)
      .then((res) => {
        setCopied(url);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleGetEvents = async () => {
    setLoading(true);
    await getEvent()
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setData(res?.data?.result);
        }
      })
      .catch((err) => {
        setLoading(false);

        handleAlert("error", `${err.message} `);
      });
  };

  useEffect(() => {
    handleGetEvents();
  }, []);
  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const formattedText = selectedRestaurantName
    .toLowerCase()
    .replace(/\s+/g, " ");
  return (
    <>
      <SEO
        title="Reisty | Restaurant Online Reservations Management"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
          canonicalUrl="https://restaurant.reisty.com/online-reservation"
      />
      {loading && <Loader />}
      <Box>
     <Header title="Online Reservation Link"  description="Shareable Link for Online Reservations"/>
        <Box sx={{ margin: "0 auto", width: "93%", mt: 5 }}>
          <Typography
            sx={{
        
              mt: 1,
              fontWeight: 500,
              width: "80%",
              fontSize: "14px",
            }}
          >
            Restaurant Profile Link
          </Typography>
          <Typography
            sx={{
        
              mt: 1,
              fontWeight: 500,
              width: "80%",
              fontSize: "12px",
            }}
          >
            Share this link on your social media or website to allow easy online
            reservations. Your guests can access your menu and book
            conveniently.
          </Typography>

          <Box sx={{ mt: 3, width: "80%" }}>
            <TextField
              fullWidth
              value={`https://www.reisty.com/restaurant/${formattedText}`}
              // value={data?.online_reservation_link}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    {copied ===
                    `https://www.reisty.com/restaurant/${formattedText}` ? (
                      <IconButton>
                        <MdOutlineDone style={{ color: "#56b85c" }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() =>
                          handleCopy(
                            `https://www.reisty.com/restaurant/${formattedText}`
                          )
                        }
                      >
                        <LiaCopy style={{ color: "#151515" }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                style: {
                  border: "1.57px solid #C56000",
                  borderRadius: "10px",
                  background: "#FFF2E5",
                  color: "#333",
                  fontWeight: 500,
                  fontSize: "12px",
                },
              }}
            />
          </Box>
          <Box
            sx={{ borderBottom: "1px solid #8f8f8f", my: 4, width: "60%" }}
          />
          <Typography
            sx={{
        
              mt: 1,
              fontWeight: 500,
              width: "80%",
              fontSize: "14px",
            }}
          >
            Widget Link
          </Typography>
          <Typography
            sx={{
        
              mt: 1,
              fontWeight: 500,
              width: "80%",
              fontSize: "12px",
            }}
          >
            Share this link on your social media or website to allow easy online
            reservations. Your guests can access your menu and book
            conveniently.
          </Typography>

          <Box sx={{ mt: 3, width: "80%" }}>
            <TextField
              fullWidth
              value={`https://www.reisty.com/${formattedText}/reservation`}
              // value={data?.online_reservation_link}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    {copied ===
                    `https://www.reisty.com/${formattedText}/reservation` ? (
                      <IconButton>
                        <MdOutlineDone style={{ color: "#56b85c" }} />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() =>
                          handleCopy(
                            `https://www.reisty.com/${formattedText}/reservation`
                          )
                        }
                      >
                        <LiaCopy style={{ color: "#151515" }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                style: {
                  border: "1.57px solid #C56000",
                  borderRadius: "10px",
                  background: "#FFF2E5",
                  color: "#333",
                  fontWeight: 500,
                  fontSize: "12px",
                },
              }}
            />
          </Box>
          <Box
            sx={{ borderBottom: "1px solid #8f8f8f", my: 4, width: "60%" }}
          />
          <Typography
            sx={{
        
              mt: 1,
              fontWeight: 500,
              width: "80%",
              fontSize: "14px",
            }}
          >
            Event Link
          </Typography>
          <Typography
            sx={{
        
              mt: 1,
              fontWeight: 500,
              width: "80%",
              fontSize: "12px",
            }}
          >
            Share this link on your social media or website to allow easy online
            reservations. Your guests can access your menu and book
            conveniently.
          </Typography>
          {!data || data?.length === 0 ? (
            <>
              <Typography
                sx={{
                  fontSize: "16px",
                  // textAlign: "center",
                  fontWeight: 700,
                  color: " #C56000",
                  my: 3,
                }}
              >
                No Event links available for this restaurant.
              </Typography>
            </>
          ) : (
            <>
              {data?.map((item, index) => (
                <Box sx={{ mt: 3, width: "80%" }}>
                  <Typography sx={{ fontFamily:'optima', mb: 1, fontWeight: 500 }}>
                    {item?.EventName}
                  </Typography>
                  <Box>
                    <TextField
                      fullWidth
                      value={`https://ticket.reisty.com/event/${item?.EventName?.split(
                        " "
                      ).join("-")}/${selectedRestaurant}`}
                      // value={data?.online_reservation_link}
                      InputProps={{
                        style: {
                          border: "1.57px solid #C56000",
                          borderRadius: "10px",
                          background: "#FFF2E5",
                          color: "#333",
                          fontWeight: 500,
                          fontSize: "12px",
                        },
                        endAdornment: (
                          <InputAdornment>
                            {copied ===
                            `https://ticket.reisty.com/event/${item?.EventName?.split(
                              " "
                            ).join("-")}/${selectedRestaurant}` ? (
                              <IconButton>
                                <MdOutlineDone style={{ color: "#56b85c" }} />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  handleCopy(
                                    `https://ticket.reisty.com/event/${item?.EventName?.split(
                                      " "
                                    ).join("-")}/${selectedRestaurant}`
                                  )
                                }
                              >
                                <LiaCopy style={{ color: "#151515" }} />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </>
          )}
          <Box sx={{ borderBottom: "2px solid #fff", my: 4, width: "60%" }} />
          {/* <Box sx={{ display: "flex", alignItems: "center", columnGap: 5 }}>
            <Box>
              <Typography sx={{ color: "#fff", fontWeight: 700 }}>
                Dark Mode Website
              </Typography>
              <Typography
                sx={{ color: "#8f8f8f", fontWeight: 400, fontSize: "12px" }}
              >
                Enabling this option will make your reservation link a dark
                themed website when users browse your link
              </Typography>
            </Box>
            <Switch size="medium" />
          </Box> */}
          <Box sx={{ mt: 4 }}>
            {/* <a href="">
              <Typography
                color="primary"
                sx={{ textDecoration: "underline", fontWeight: 500 }}
              >
                Click here to view how your profile appears for online
                reservation
              </Typography>
            </a> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OnlineReservation;
