import { Box, Typography } from "@mui/material";
import { MdOutlineStar } from "react-icons/md";
import React, { useEffect, useState } from "react";

const RatingBar = ({rate, value}) => {

 const [values, setValues] = useState(0)

 useEffect(()=>{
  setValues(0)

  setTimeout(()=>{
   setValues(value)
  }, [100])
 }, [value])
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", columnGap: 0.5 }}>
        <Typography sx={{ color: "#fff", fontSize:'12px' }}>{rate}</Typography>
        <MdOutlineStar style={{ color: "#DF8E14" }} />
        <Box
          sx={{
            bgcolor: "#393939",
            height: "13px",
            width: "100%",
            borderRadius: "2px",
          }}
        >
          <Box
            sx={{
              height: "100%",
              transition:'0.4s all linear',
              width: `${values}%`,
              display: "grid",
              placeItems: "center",
              bgcolor: "#BC172F",
              borderRadius: "3px",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "8px",
                lineHeight: "0px",
                fontWeight: 500,
              }}
            >
             {values}%
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RatingBar;
