import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialTime }) => {
  const parseTimeString = (timeString) => {
    const [hours, minutes, seconds] = timeString
      .split(/ hr | mins | secs/)
      .filter(Boolean)
      .map((val) => parseInt(val.trim(), 10));
    return { hours, minutes, seconds: seconds || 0 };
  };

  const convertToSeconds = ({ hours, minutes, seconds }) =>
    hours * 3600 + minutes * 60 + seconds;

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s `;
  };

  const [timeInSeconds, setTimeInSeconds] = useState(() =>
    convertToSeconds(parseTimeString(initialTime))
  );

  useEffect(() => {
    if (timeInSeconds <= 0) return;

    const timer = setInterval(() => {
      setTimeInSeconds((prev) => Math.max(prev - 1, 0));
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [timeInSeconds]);

  return <div >{formatTime(timeInSeconds)}</div>;
};

export default CountdownTimer;
