import React, { useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Grid,
  Popover,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 400, xs: 350 },
  bgcolor: "#1a1a1a",
  boxShadow: 24,
      borderRadius:'10px',
  p: 0,
};

const Notes = ({ value, type, borderColor, children }) => {
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = (event) => {
    setOpen2(true);
        event.stopPropagation();}
  const handleClose2 = () => setOpen2(false);

  return (
    <>
    <Box  sx={{width:'fit-content', cursor:'pointer'}}      onClick={handleOpen2}>
   {children}
      </Box>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <Box
            sx={{
              p: 1,
              px: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: `1px solid ${borderColor}`,
              borderRadius:'10px'
            }}
          >
            <Typography
              sx={{ color: borderColor, fontWeight: 500, fontSize: "12px" }}
            >
              {type === "visit" ? "Visit Note" : "Dietary Restrictions"}
            </Typography>
            <IconButton onClick={handleClose2}>
              <CloseOutlined sx={{ color: "#fff" }} />
            </IconButton>
          </Box>

          <Box sx={{ mt: 3, px: 4, pb: 4 }}>
            <TextField
              fullWidth
              multiline
              value={value}
              rows={6}
              InputProps={{
                style: {
                  fontFamily: "outfit",
                  fontSize: "12px",
                  borderRadius: "10px",
                  offset: " 1px solid #ccc",
                  color: "#ccc",
                  fontWeight: 500,
                  border: "1px solid #ccc",
                },
              }}
            />

            <Box sx={{ mt: 4 }}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleClose2}
                sx={{
                  background: borderColor,
                  "&:hover": { background: "none" },
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Notes;
