import { Button, Box, Typography, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import CustomTextField from "../../../CustomField/CustomTextField";

import {
  editBasicInfo,
  editBasicInfoDescription,
} from "../../../../../axios/api";
import Loader from "../../../Common/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 600, md: 600, sm: 400, xs: 350 },
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  maxHeight: "70vh",
};
const Section3 = ({ data, action }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDescription(data?.Description);
  }, [data]);

  const handleEdit = async () => {
    setIsLoading(true);
    await editBasicInfoDescription(description)
      .then((res) => {
        setIsLoading(false);
        setOpen(false);
        action();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Button
      variant="outlined"
        onClick={handleOpen}
        sx={{width:'100px'}}
      >
        Edit
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading && <Loader />}
          <Box
            sx={{
              height: "60px",
              bgcolor: "background.card",
              borderBottom: "1px solid #BC172F",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 2,
                py: 2,
              }}
            >
              <Grid item lg={4} md={4}></Grid>
              <Grid item lg={4} md={4} xs={8}>
                <Typography
                  sx={{
                    fontFamily: "optima",
            
                    textAlign: "center",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    fontWeight: 700,
                  }}
                >
                  Edit Description
                </Typography>
              </Grid>
              <Grid item align="right" lg={4} md={4} xs={4}>
                <IconButton onClick={handleClose}>
                  <CloseIcon sx={{ }} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              bgcolor: "background.paper",
              px: { lg: 5, md: 5, sm: 3, xs: 2 },
              // height: "600px",
              overflow: "scroll",
              py: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "outfit",
               
                textAlign: "center",
                fontSize: { lg: "12px", md: "12px", sm: "12px", xs: "12px" },
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Highlight what sets your establishment apart with a unique
              description on Reisty. Guests can easily discover your restaurant,
              making a well-crafted description essential for driving bookings
              and repeat business.
            </Typography>

            <Grid container sx={{ mt: 3 }} spacing={2} rowGap={1}>
              <Grid item lg={12} xs={12}>
                <CustomTextField
                  name="Restaurant Description"
                  multiLine={true}
                  rows={6}
                  value={description}
                  onChange={(e) =>
                    description.length < 2000 && setDescription(e.target.value)
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mt: 1,
                  }}
                >
                  <Typography
                    sx={{ fontSize: "10px", fontWeight: 500,  }}
                  >
                    {description?.length}/2000
                  </Typography>
                  <Typography
                    sx={{ fontSize: "10px", fontWeight: 500, }}
                  >
                    2000 characters
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, mb: 3 }} align="right">
              <Button
                disabled={description?.length > 2000}
                variant="contained"
                sx={{
                  px: 5,
                  py: 1.5,
                  borderRadius: "10px",
                  "&:disabled": {
                    bgcolor: "#333",
                  },
                }}
                onClick={handleEdit}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Section3;
