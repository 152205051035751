import React, {useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { refreshToken } from '../axios/api';
import { setUser } from '../store/userSlice';
import { useLocation } from 'react-router-dom';

const RefreshToken = () => {
  const dispatch = useDispatch()
    const user = JSON.parse(window.localStorage.getItem("userInfo"));
    // const location = useLocation();

    const handleRefreshToken = async () => {
    await refreshToken(user?.refresh_token)
      .then((res) => {
        if(res?.data?.status){

        localStorage.setItem("userInfo", JSON.stringify(res?.data?.result));
        dispatch(setUser(res?.data?.result));
        }
   
      })
      .catch((err) => {
   

      });
  };

  useEffect(()=>{
    handleRefreshToken()
  }, [])

  return (
 null
  )
}

export default RefreshToken