import { createTheme } from "@mui/material";

const themeDark = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#BC172F",
    },
    background: {
      default: "#000000",
      paper: "#1a1a1a",
      card:'#242424',
      shadow:'none'
      
    },
    text: {
      primary: "#ffffff",
    },
  },
  typography: {
    fontFamily: "outfit",
    color: '#ffffff',
    fontSize: { lg: '14px', md: '14px', sm: '12px', xs: '12px' },
    fontWeight: 300,
    h1: {
      fontSize: "32px",
      fontWeight: 900,
    },
    h2: {
      fontSize: "24px",
      fontWeight: 700,
      fontFamily: "butler",
    },
    h3: {
      fontSize: "20px",
      fontWeight: 700,
      fontFamily: "outfit",
    },
    h6: {
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "outfit",
          color: "#B3B3B3",
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "outfit",
      lineHeight: "20px",
    },
    body2: {
      fontSize: "13px",
      fontWeight: 400,
      fontFamily: "outfit",
    },
    subtitle1: {
      fontSize: "13px",
      fontWeight: 500,
      fontFamily: "outfit",
    },
    subtitle2: {
      fontSize: "12px",
      color: "#8f8f8f",
      fontWeight: 500,
      fontFamily: "outfit",
    },
    button: {
      textTransform: "initial",
      fontFamily: "outfit",
      "disabled": {
        "background": "#5b5b5b"
      }
    },
  },
   components: {
    MuiBox: {
      defaultProps: {
        sx: {
          padding: '16px',
          borderRadius: '8px',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)',
            backgroundColor: 'background.paper',
        },
      },
    },
      MuiListItemButton: {
      styleOverrides: {
        root: {

         color:'#fff',
          '&.Mui-selected': {
            backgroundColor: '#333', // The selected color for the ListItemButton
            color: '#ffffff',           // Text color when selected (optional)
            '&:hover': {
              backgroundColor: '#a31428', // Hover color when selected
            },
          },
        },
      },
    },
  },
});

export default themeDark;
