import React, { useState, useEffect } from "react";
import { alpha, styled } from "@mui/material/styles";
import { RiSearch2Line } from "react-icons/ri";
import { RiArrowDropDownLine } from "react-icons/ri";
import { BiSolidEdit } from "react-icons/bi";
import { addTeamMember, deleteTeamMember, getMembers, getPermissions } from "../../../axios/api";
import { IoIosRefresh } from "react-icons/io";
import { CloseOutlined } from "@mui/icons-material";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Skeleton,
  CircularProgress,
  Dialog,
  Button,
  InputLabel,
  Grid,
  Select
} from "@mui/material";
import Loader from "../Common/Loader";
import { useSnackbar } from "notistack";

const CssTextField = styled(TextField)({
  height: "30px",
 
  fontWeight: 500,
  fontSize:'10px',
  "& label.Mui-focused": {
   
  },
  "& .MuiInput-underline:after": {

   
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
  
     
      fontWeight: 500,
    },
    "&:hover fieldset": {
  
     
      fontWeight: 500,
    },
    "&.Mui-focused fieldset": {
  
     
      fontWeight: 500,
    },
  },
});

const Roles = [
  "Owner",
  "Admin/Manager",
  "Restaurant manager",
  "Restaurant host",
  "Waiter",
  "Front Desk",
  "Other",
];

const TeamMembers = ({handleEdit}) => {
  const [teamLoading, setTeamLoading] = useState(false);
  const [members, setMembers] = useState(null);
  const [open, setOpen] = React.useState(false);
    const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Owner");
  const [permission, setPermission] = useState(null)
  const [selectedPermissions, setSelectedPermissions] = useState([])
const restaurantName = localStorage.getItem("restaurantName")
  const { enqueueSnackbar } = useSnackbar();

    const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleGetTeam = async () => {
    setTeamLoading(true);
    await getMembers()
      .then((res) => {
        setTeamLoading(false);
      
        if(res?.data?.status){
         setMembers(res?.data?.result)
        }
      })
      .catch((err) => {
        setTeamLoading(false);


      });
  };

  useEffect(() => {
    handleGetTeam();
    handleGetPermissions()
  }, []);

  const [permissionLoading, setPermissionLoading] = useState(false);

  const handleGetPermissions = async () =>{
   setPermissionLoading(true);
   await getPermissions()
  .then((res)=>{
   setPermissionLoading(false)
   setPermission(res?.data)

  }).catch((err)=>{
    setPermissionLoading(false)

  })
  }

    const handleSelectionChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPermissions(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const [isLoading, settIsLoading] = useState(false)

  const handleAddTeamMember = async  () =>{
settIsLoading(true);

const permissionsObject = selectedPermissions?.reduce((acc, permission) => {
  acc[permission] = true;
  return acc;
}, {});

await addTeamMember(restaurantName, firstName, lastName,email, role, permissionsObject)
.then((res)=>{
 settIsLoading(false);
 if(res?.data?.status){
       handleAlert("success", `${res?.data?.success_message}`);
       setFirstName("");
       setLastName("");
       setEmail("")
       handleClose();
       handleGetTeam()
 }else{
      handleAlert("error", `${res?.data?.error_message}`);
 }

}).catch((err)=>{
         handleAlert("error", `${err?.response?.data?.
error_message}`);
 settIsLoading(false);

})
  }

const handleDeleteTeam = async (id) =>{
  await deleteTeamMember(id)
}

  return (
    <>
    {
     isLoading && <Loader/>
    }
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 6,
        }}
      >
        <Box>
          <Typography sx={{fontWeight: 500 }}>
            Member Permissions
          </Typography>
          <Typography variant="caption" sx={{ fontSize: "12px", }}>
            Crafting a distinctive description of your restaurant aids potential
            diners in discovering your establishment and what sets it apart.
          </Typography>
        </Box>
        <Button
          onClick={handleClickOpen}
          variant="outlined"
          sx={{
            bgcolor: "#1a1a1a",
            border: "1px solid #dadada",
            color: "#fff",
          }}
        >
          Add New Member
        </Button>
      </Box>
      <Box sx={{  bgcolor: "background.card", borderRadius: "8px", mt: 2, px: 3, py: 3 , boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 4,
              alignItems: "center",
              mb: 2,
              width: "50%",
            }}
          >
            <TextField
              size="small"
              select
              sx={{ width: "50%" }}
              InputProps={{
                style: {
                  color: "text.primary",
                  fontSize: "12px",
                  // border: "1px solid #fff",
                  borderRadius: "8px",
                },
              }}
            >
              <MenuItem value="Last Week" sx={{ fontSize: "10px" }}>
                All
              </MenuItem>
              <MenuItem value="Owner" sx={{ fontSize: "10px" }}>
                Owner
              </MenuItem>
              <MenuItem value="Admin" sx={{ fontSize: "10px" }}>
                Admin
              </MenuItem>
            </TextField>
            <TextField
              size="small"
              sx={{ width: "50%" }}
              placeholder="Search Members"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RiSearch2Line style={{ color: "#fff" }} />
                  </InputAdornment>
                ),
                style: {
                   color: "text.primary",
                  fontSize: "12px",
                  // border: "1px solid #fff",
                  borderRadius: "8px",
                },
              }}
            />
          </Box>
          <Box>
            {teamLoading ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton onClick={handleGetTeam}>
                <IoIosRefresh style={{ color: "#fff" }} />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 1 }}>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  borderTop: "1px solid #4c4c4c",
                  borderBottom: "1px solid #4c4c4c",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                  
                      fontFamily: "outfit",
                      fontSize: "12px",
                      border: "none",
                      fontWeight: 500,
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                  
                      fontFamily: "outfit",
                      fontSize: "12px",
                      border: "none",
                      fontWeight: 500,
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                  
                      fontFamily: "outfit",
                      fontSize: "12px",
                      border: "none",
                      fontWeight: 500,
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                  
                      fontFamily: "outfit",
                      fontSize: "12px",
                      border: "none",
                      fontWeight: 500,
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              {teamLoading ? (
                <TableBody>
                  {[1, 2, 3]?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                      
                          fontFamily: "outfit",
                          fontSize: "12px",
                          border: "none",
                          fontWeight: 500,
                        }}
                      >
                        <Skeleton
                          animation="wave"
                          sx={{ borderRadius: "0px" }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                      
                          fontFamily: "outfit",
                          fontSize: "12px",
                          border: "none",
                          fontStyle: "italic",
                        }}
                      >
                        <Skeleton
                          animation="wave"
                          sx={{ borderRadius: "0px" }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                      
                          fontFamily: "outfit",
                          fontSize: "12px",
                          border: "none",
                        }}
                      >
                        <Skeleton
                          animation="wave"
                          sx={{ borderRadius: "0px" }}
                        />
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                      
                          fontFamily: "outfit",
                          fontSize: "12px",
                          border: "none",
                        }}
                      >
                        <IconButton>
                          <Skeleton
                            animation="wave"
                            sx={{
                              borderRadius: "0%",
                              width: "40px",
                              height: "40px",
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
           <>
           {
            (!members || members?.length === 0) ? (
             <>

             </>
            ):(
             <>
     <TableBody>
                  {members?.map((item, index) => (
                    <TableRow key={index} sx={{opacity:!item?.EmailConfirmed && 0.4}}>
                      <TableCell
                        sx={{
                      
                          fontFamily: "outfit",
                          fontSize: "12px",
                          border: "none",
                          fontWeight: 500,
                          display:'flex', 
                          alignItems:'center',
                          columnGap:2
                        }}
                      >
                      {`${item?.FirstName} ${item?.LastName}`}

{
 !item?.EmailConfirmed && (
 <Typography sx={{bgcolor:'#DF8E14', fontSize:'8px', color:'#fff', borderRadius:'50px', px:1}}>Pending</Typography>
 )
}

                      </TableCell>
                      <TableCell
                        sx={{
                      
                          fontFamily: "outfit",
                          fontSize: "12px",
                          border: "none",
                          fontStyle: "italic",
                        }}
                      >
                     {item?.Email}
                      </TableCell>
                      <TableCell
                        sx={{
                      
                          fontFamily: "outfit",
                          fontSize: "12px",
                          border: "none",
                        }}
                      >
                     {item?.RoleDepartment || "--"}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                      
                          fontFamily: "outfit",
                          fontSize: "12px",
                          border: "none",
                        }}
                      >
                     {
                      item?.RoleDepartment !== "Owner" && (
                           <IconButton>
                          <BiSolidEdit style={{ color: "#757F8F" }}  onClick={()=>handleEdit(item)}/>
                        </IconButton>
                      )
                     }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
             </>
            )
           }
           </>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <Box sx={{ width: 600, bgcolor: "background.paper", borderRadius:'10px' }}>
          <Box
            sx={{
              display: "flex",
              px: 2,
              py: 2,
              alignItems: "center",
              justifyContent: "space-between",
              bgcolor: "background.card",
              borderBottom: "1px solid #BC172F",
            }}
          >
            <Typography sx={{ fontWeight: 700, fontFamily:'optima', fontSize:'16px' }}>
              Add a teammate
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined  style={{fontSize:'25px'}}/>
            </IconButton>
          </Box>

          <Box
            sx={{
              mt: 4,
              boxSizing: "border-box",
              bgcolor: "background.card",
              mx: 4,
              borderRadius: "8px",
            }}
          >
            <Box sx={{ borderBottom: "0.5px solid #333", py: 2, px: 3 }}>
              <Grid container>
                <Grid item lg={4} md={4}>
                  <Typography
                    sx={{fontSize: "12px", fontWeight: 400 }}
                  >
                    First Name *
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8}>
                  <Box sx={{}}>
                    <CssTextField
                      fullWidth
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      InputProps={{
                        style: {
                          fontFamily: "outfit",
                          fontSize: "10px",
                          borderRadius: "10px",
                        
                          fontWeight: 400,
                        },
                      }}
                      size="small"
                      placeholder="Enter first name"
                      id="custom-css-outlined-input"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ borderBottom: "0.5px solid #333", py: 2, px: 3 }}>
              <Grid container>
                <Grid item lg={4} md={4}>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    Last Name *
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8}>
                  <Box sx={{}}>
                    <CssTextField
                      fullWidth
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      InputProps={{
                        style: {
                          fontFamily: "outfit",
                          fontSize: "10px",
                          borderRadius: "10px",
                         
                          fontWeight: 400,
                        },
                      }}
                      size="small"
                      placeholder="Last Name"
                      id="custom-css-outlined-input"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ borderBottom: "0.5px solid #333", py: 2, px: 3 }}>
              <Grid container>
                <Grid item lg={4} md={4}>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    Email
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8}>
                  <Box sx={{}}>
                    <CssTextField
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{
                        style: {
                          fontFamily: "outfit",
                          fontSize: "10px",
                          borderRadius: "10px",
                         
                          fontWeight: 400,
                        },
                      }}
                      size="small"
                      placeholder="Email"
                      id="custom-css-outlined-input"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ borderBottom: "0.5px solid #333", py: 2, px: 3 }}>
              <Grid container>
                <Grid item lg={4} md={4}>
                  <Typography
                    sx={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    Role
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8}>
                  <Box sx={{}}>
                    <CssTextField
                      fullWidth
                      select
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      InputProps={{
                        style: {
                          fontFamily: "outfit",
                          fontSize: "10px",
                          borderRadius: "10px",
                         
                          fontWeight: 500,
                        },
                      }}
                      size="small"
                      placeholder="Choose"
                      id="custom-css-outlined-input"
                    >
                      {Roles?.map((item, i) => (
                        <MenuItem
                          key={i}
                          value={item}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "10px" }}>
                            {item}
                          </Typography>
                          {/* <IoCheckmarkDoneCircle /> */}
                        </MenuItem>
                      ))}
                    </CssTextField>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{  py: 2, px: 3 }}>
              <Grid container>
                <Grid item lg={4} md={4}>
                  <Typography
                    sx={{ fontSize: "13px", fontWeight: 400 }}
                  >
                    Permission
                  </Typography>
                </Grid>
                <Grid item lg={8} md={8}>
                  <Box sx={{}}>
                   <Select
      fullWidth
      multiple
      value={selectedPermissions}
      onChange={handleSelectionChange}
      renderValue={(selected) => selected.join(', ')}
      sx={{border:'none', fontSize:'10px', borderColor:'#333'}}
      inputProps={{
        style: {
          fontFamily: 'outfit',
          fontSize: '10px',
          borderRadius: '10px',
          color: '#fff',
          fontWeight: 400,
        },
      }}
      size="small"
      placeholder="permission"
      id="custom-css-outlined-input"
    >
      {!permission || permission.length === 0 ? (
        <MenuItem disabled>
          <Box sx={{ fontSize: '10px', color: '#fff' }}>
            No Available Permissions
          </Box>
        </MenuItem>
      ) : (
        permission.map((item) => (
          <MenuItem key={item} value={item} sx={{  fontSize: '10px' }}>
            {item}
          </MenuItem>
        ))
      )}
    </Select>
                  </Box>
                </Grid>
              </Grid>
            </Box>

       
          </Box>
               <Box sx={{px:4, mt:3, mb:5}} align="right">
             <Button variant="contained" onClick={handleAddTeamMember} sx={{py:2, borderRadius:'8px', px:5}} disabled={!firstName || !lastName || !email || !selectedPermissions || !role}>Continue</Button>
            </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default TeamMembers;
