import {
  Avatar,
  Backdrop,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { RiLock2Line } from "react-icons/ri";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import { usePaystackPayment } from "react-paystack";
import { PaystackButton } from "react-paystack";
import { refreshToken, sendSubscription } from "../../../axios/api";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../store/userSlice";

const Billings = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(window.localStorage.getItem("userInfo"));

  const config = {
    reference: new Date().getTime().toString(),
    email: user?.email,
    amount:30000 * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: process.env.REACT_APP_PAYSTACK_CODE,
    // publicKey: "pk_test_6c0b3d295bf7fd5d570b36fdfde7a6c5efcc9a73",
    firstName: user?.first_name,
    lastName: user?.last_name,
  };

  const initializePayment = usePaystackPayment(config);

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.

  };

  const componentProps = {
    ...config,
    text: "Pay with Paystack",
    className: `${
      !user?.first_name || !user?.last_name || !user?.email
        ? "paystack-button-disabled"
        : "paystack-button"
    }`,
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const [loadingText, setLoadingText] = useState("")
  const [isLoading, setIsLoading] = useState(false);

  const handleRefreshToken = async () => {
        setLoadingText("Configuring your dashboard...")
    setIsLoading(true);

    await refreshToken(user?.refresh_token)
      .then((res) => {
        setIsLoading(false);
        if(res?.data?.status){

        localStorage.setItem("userInfo", JSON.stringify(res?.data?.result));
        dispatch(setUser(res?.data?.result));
        }
  
      })
      .catch((err) => {
        setIsLoading(false);

      });
  };

  const handleSendSubscription = async (reference) => {
    setLoadingText("Confirming your payment...")
    setIsLoading(true);
    await sendSubscription(reference)
      .then((res) => {
        if (res?.data?.status) {
          setIsLoading(false);
          handleRefreshToken();

        }
      })
      .catch((err) => {
        setIsLoading(false);

      });
  };

  const handlePaystackSuccessAction = (reference) => {
    handleSendSubscription(reference?.reference);

  };
  const [activeStep, setActiveStep] = useState(0);
  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          width: "95%",
          height: "90vh",
          overflow: "scroll",
          mt:4,
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar for WebKit browsers
          },
        }}
      >
        {activeStep === 0 && (
          <AnimatePresence mode="popLayout">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar sx={{ bgcolor: "background.default", border: "1px solid #BC172F" }}>
                <RiLock2Line style={{ color: "#BC172F" }} />
              </Avatar>

              <Typography sx={{ mt: 4, textAlign: "center" }}>
                STEP 1 OF 2
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                 
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "18px",
                }}
              >
                Select a subscription plan
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                 
                  textAlign: "center",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                Your access to these features are unlocked as soon as you elect
                a payment plan and set up your payment
              </Typography>

              <Box
                sx={{
                  mt: 5,
                  display: "flex",
                  alignItems: "center",
                  columnGap: 6,
                }}
              >
                <Box
                  component={motion.div}
                  animate={{
                    scale: 1,
                  }}
                  initial={{
                    scale: 0,
                  }}
                  transition={{
                    duration: 0.3,
                    ease: "backInOut",
                  }}
                  sx={{
                    bgcolor: "#fff",
                    p: 2,
                    boxSizing: "border-box",
                    border: "1px solid #dadada",
                    borderRadius: "15px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                  >
                    <Typography sx={{color:'text.primary'}}>Dollop</Typography>
                    <Typography
                      sx={{
                        bgcolor: "#189B62",
                        px: 0.5,
                        borderRadius: 2,
                        fontSize: "10px",
                       
                        fontWeight: 400,
                      }}
                    >
                      Current Plan
                    </Typography>
                  </Box>
                  <Typography
                    color={"primary"}
                    sx={{ fontWeight: 900, fontSize: "18px", mt: 1 }}
                  >
                    Free
                  </Typography>
                  <Typography
                    color={"primary"}
                    sx={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    + booking deposit fee (starting at N500 per diner)
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IoIosCheckmarkCircle style={{ color: "#BC172F" }} />
                      <Typography
                        sx={{
                          color: "#9b9b9b",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        Reservation, waitlist, and table management
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IoIosCheckmarkCircle style={{ color: "#BC172F" }} />
                      <Typography
                        sx={{
                          color: "#9b9b9b",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        Menu Management
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IoIosCheckmarkCircle style={{ color: "#BC172F" }} />
                      <Typography
                        sx={{
                          color: "#9b9b9b",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        Ticketed events, experiences, and add-ons
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IoIosCheckmarkCircle style={{ color: "#BC172F" }} />
                      <Typography
                        sx={{
                          color: "#9b9b9b",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        Post-dining guest survey management
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        textAlign: "center",
                        mt: 5,
                        fontSize: "12px",
                        fontStyle: "italic",
                        fontWeight: 500,
                      }}
                    >
                      You’ve outgrown this plan
                    </Typography>
                  </Box>
                </Box>
                <Box
                  component={motion.div}
                  animate={{
                    scale: 1,
                  }}
                  initial={{
                    scale: 0,
                  }}
                  transition={{
                    duration: 0.8,
                    ease: "backInOut",
                  }}
                  sx={{
                    bgcolor: "#fff",
                    p: 2,
                    boxSizing: "border-box",
                    border: "1px solid #dadada",
                    borderRadius: "15px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                  >
                    <Typography sx={{color:'text.primary'}}>Splash</Typography>
                    <Typography
                      sx={{
                        bgcolor: "#BC172F",
                        px: 0.5,
                        borderRadius: 2,
                        fontSize: "10px",
                       
                        fontWeight: 400,
                      }}
                    >
                      + 30 day free trial
                    </Typography>
                  </Box>
                  <Typography
                    color={"primary"}
                    sx={{ fontWeight: 900, fontSize: "18px", mt: 1 }}
                  >
                    ₦30,000
                    <span style={{ fontWeight: 500, fontSize: "12px" }}>
                      {" "}
                      monthly per restaurant
                    </span>
                  </Typography>
                  <Typography
                    color={"primary"}
                    sx={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    + booking deposit fee (starting at N350 per diner)
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IoIosCheckmarkCircle style={{ color: "#BC172F" }} />
                      <Typography
                        sx={{
                          color: "#9b9b9b",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        All of Dollop plan features
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IoIosCheckmarkCircle style={{ color: "#BC172F" }} />
                      <Typography
                        sx={{
                          color: "#9b9b9b",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        Data & Analytics Dashboards
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IoIosCheckmarkCircle style={{ color: "#BC172F" }} />
                      <Typography
                        sx={{
                          color: "#9b9b9b",
                          fontSize: "12px",
                          fontWeight: 400,
                          lineHeight: "18px",
                        }}
                      >
                        Guest Database with preferences, special occassions{" "}
                        <br />
                        and visits history
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: 2,
                      }}
                    >
                      <IoIosCheckmarkCircle style={{ color: "#BC172F" }} />
                      <Typography
                        sx={{
                          color: "#9b9b9b",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        Multiple locations management
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        textAlign: "center",
                        mt: 5,
                        fontSize: "12px",
                        fontStyle: "italic",
                        fontWeight: 500,
                      }}
                    >
                      <Button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9, rotate: "2.5deg" }}
                        transition={{
                          duration: 0.2,
                          ease: "easeInOut",
                        }}
                        component={motion.div}
                        variant="contained"
                        onClick={() => setActiveStep(1)}
                        sx={{ width: "60%", py: 1, borderRadius: "10px" }}
                      >
                        Upgrade
                      </Button>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </AnimatePresence>
        )}

        {activeStep === 1 && (
          <AnimatePresence mode="popLayout">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar sx={{ bgcolor: "#000", border: "1px solid #BC172F" }}>
                <RiLock2Line style={{ color: "#BC172F" }} />
              </Avatar>

              <Typography sx={{ mt: 4, textAlign: "center" }}>
                STEP 2 OF 2
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                 
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "18px",
                }}
              >
                Make Payment
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                 
                  textAlign: "center",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                Your access to these features are unlocked as soon as you elect
                a payment plan and set up your payment
              </Typography>

              <Box
                sx={{
                  mt: 5,
                  display: "flex",
                  alignItems: "center",
                  columnGap: 6,
                }}
              >
                <Box
                  component={motion.div}
                  animate={{
                    scale: 1,
                    x: 0,
                  }}
                  initial={{
                    scale: 1,
                    x: -200,
                  }}
                  transition={{
                    duration: 0.8,
                    ease: "backInOut",
                  }}
                  sx={{
                    bgcolor: "#fff",
                    p: 2,
                    boxSizing: "border-box",
                    border: "1px solid #dadada",
                    borderRadius: "15px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 2 }}
                  >
                    <Typography>Splash</Typography>
                    <Typography
                      sx={{
                        bgcolor: "#BC172F",
                        px: 0.5,
                        borderRadius: 2,
                        fontSize: "10px",
                       
                        fontWeight: 400,
                      }}
                    >
                      + 30 day free trial
                    </Typography>
                  </Box>
                  <Typography
                    color={"primary"}
                    sx={{ fontWeight: 900, fontSize: "18px", mt: 1 }}
                  >
                    ₦30,000
                    <span style={{ fontWeight: 500, fontSize: "12px" }}>
                      {" "}
                      monthly per restaurant
                    </span>
                  </Typography>
                  <Typography
                    color={"primary"}
                    sx={{ fontWeight: 500, fontSize: "14px" }}
                  >
                    + booking deposit fee (starting at N350 per diner)
                  </Typography>
                </Box>
              </Box>

              {/* <Button
                variant="contained"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9, rotate: "2.5deg" }}
                transition={{
                  duration: 0.2,
                  ease: "easeInOut",
                }}
                component={motion.div}
                sx={{ mt: 3 }}
              >
                Pay with Paystack
              </Button> */}
              <PaystackButton disabled {...componentProps} />

              <IconButton
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9, rotate: "2.5deg" }}
                transition={{
                  duration: 0.2,
                  ease: "easeInOut",
                }}
                component={motion.div}
                sx={{ mt: 6 }}
                onClick={() => setActiveStep(0)}
              >
                <IoArrowBackCircleOutline
                  style={{ fontSize: "40px", color: "#BC172F" }}
                />
              </IconButton>
            </Box>
          </AnimatePresence>
        )}
      </Box>

      {isLoading && (
        <>
          <Backdrop
            open={isLoading}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 5 }}
          >
            <Box sx={{ width: "30vw" }}>
              <Typography
                sx={{ textAlign: "center", fontWeight: 500, color: "#fff" }}
              >
          {loadingText}
              </Typography>
              <LinearProgress />
            </Box>
          </Backdrop>
        </>
      )}
    </>
  );
};

export default Billings;
