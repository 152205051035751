import React, { useState } from "react";
import Navbar from "../../Component/Header/Navbar";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import logo from "../../../assets/Logo/logo2.svg";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import Snackbar from "@mui/material/Snackbar";
import { useSnackbar } from "notistack";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { resetPassword } from "../../../axios/api";
import sucIcon from "../../../assets/Icons/reset.svg";
import { Link } from "react-router-dom";
import SEO from "../../../Utility/SEO";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  React.useEffect(() => {
    // Function to extract token from URL
    const extractTokenFromURL = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromURL = urlParams.get("token");
      if (tokenFromURL) {
        // Set the token state with the extracted token
        setToken(tokenFromURL);
      }
    };

    // Call the function when the component mounts
    extractTokenFromURL();
  }, []);

  const handleResetPassword = async () => {
    setIsSuccessful(false);
    setIsLoading(true);
    await resetPassword(email, token, password, confirmPassword)
      .then((res) => {
        setIsLoading(false);

        if (res?.data?.status) {
          setIsSuccessful(true);
        } else {
          handleAlert("error", `${res?.data?.error_message}`);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        handleAlert("error", `${err.message}`);
      });
  };
  return (
    <>
      <SEO
        title="Reisty | Reset Password"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
      />
      <Navbar />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            p: { lg: 6, md: 6, xs: 3 },
            bgcolor: "#fff",
            boxShadow: 24,
            width: { lg: "35%", md: "40%", xs: "90%" },
            boxSizing: "border-box",
          }}
        >
          <img src={logo} width={70} alt="logo" title="Reisty" />
          {isLoading && <LinearProgress />}
          {isSuccessful ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <img src={sucIcon} alt="icon" />
                <Typography
                  sx={{
                    mt: 3,
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Your password has been successfully updated. The new password
                  you have chosen is now in effect, and you can use it to log in
                  to your account. Please make sure to remember your new
                  password to avoid any inconvenience in the future.
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Link to="/login">
                    <Button
                      variant="contained"
                      sx={{ py: 2, px: 5, borderRadius: "14px" }}
                    >
                      Back to Login
                    </Button>
                  </Link>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ mt: 6 }}>
                <Typography sx={{ fontWeight: 700, fontSize: "28px", fontFamily:'optima' }}>
                  Reset Password
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    mt: 2,
                  }}
                >
                  To prevent any further instances of forgetting your password,
                  kindly generate a new one and re-enter it
                </Typography>

                <Box sx={{ mt: 3 }}>
                  <InputLabel
                    sx={{
                      color: "#2B2B2B",
                      fontSize: "13px",
                      fontFamily: "outfit",
                      textAlign: "left",
                      fontWeight: 500,
                    }}
                  >
                    Email
                  </InputLabel>
                  <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter registered email"
                    margin="dense"
                    size="large"
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "outfit",
                        fontSize: "13px",
                        borderRadius: "10px",
                       border:'1px solid #151515',
                        fontWeight: 500,
                        color:"#151515"
                      },
                    }}
                  />
                  <InputLabel
                    sx={{
                      color: "#2B2B2B",
                      fontSize: "13px",
                      fontFamily: "outfit",
                      textAlign: "left",
                      fontWeight: 500,
                      mt: 2,
                    }}
                  >
                    New Password
                  </InputLabel>
                  <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="**********"
                    type={showPassword ? "text" : "password"}
                    margin="dense"
                    size="large"
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "outfit",
                        fontSize: "13px",
                        borderRadius: "10px",
                  border:'1px solid #151515',
                        fontWeight: 500,
                                     color:"#151515"
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOutlinedIcon
                                sx={{ fontSize: "16px" }}
                              />
                            ) : (
                              <VisibilityOffOutlinedIcon
                                sx={{ fontSize: "16px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <InputLabel
                    sx={{
                      color: "#2B2B2B",
                      fontSize: "13px",
                      fontFamily: "outfit",
                      textAlign: "left",
                      fontWeight: 500,
                      mt: 2,
                    }}
                  >
                    Confirm Password
                  </InputLabel>
                  <TextField
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="********"
                    margin="dense"
                    size="large"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "outfit",
                        fontSize: "13px",
                        borderRadius: "10px",
                         border:'1px solid #151515',
                        fontWeight: 500,
                                     color:"#151515"
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOutlinedIcon
                                sx={{ fontSize: "16px" }}
                              />
                            ) : (
                              <VisibilityOffOutlinedIcon
                                sx={{ fontSize: "16px" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Button
                    disabled={
                      !email || !password || !confirmPassword || isLoading
                    }
                    onClick={handleResetPassword}
                    variant="contained"
                    sx={{ py: 2, borderRadius: "14px", "&:disabled":{bgcolor:'#333'} }}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
