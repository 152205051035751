import React from "react";
import Navbar from "../Component/Header/Navbar";
import Footer from "../Component/Footer/Footer";
import "../../STYLES/main.css";
import {
  Box,
  Button,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  InputLabel,
  TextField,
} from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RequestForm from "../Component/Common/RequestForm";
import { motion } from "framer-motion";
import { GiCheckMark } from "react-icons/gi";
import { BsNodePlusFill } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import SEO from "../../Utility/SEO";

const Pricing = () => {
  const theme = useTheme();
  const fadeInOutVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };
  return (
    <>
      <SEO
        title="Reisty | Pricing"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
                canonicalUrl="https://restaurant.reisty.com/pricing"
      />
      <motion.div
        variants={fadeInOutVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
      >
        <Navbar />
        <Box
          className="food_background"
          sx={{
            height: "270px",
            bgcolor: theme.palette.primary.main,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 8,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              color: "#fff",
              fontFamily: "outfit",
              fontSize: { lg: "30px", md: "30px", sm: "20px", xs: "20px" },
            }}
          >
            Pricing Plans
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              textAlign: "center",
              fontFamily: "outfit",
              fontSize: { lg: "18px", md: "18px", sm: "16px", xs: "14px" },
              mt: 2,
            }}
          >
            Reisty offers flexible plans to our platform and its features.
            Upgrade or downgrade your plans at anytime with no penalties
          </Typography>
        </Box>

        <Box
          className="food_background"
          sx={{
            bgcolor: theme.palette.primary.main,
            py: { lg: 0, md: 0, sm: 0, xs: 0 },
            mb: 3,
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              width: { lg: "50%", md: "55%", sm: "85%", xs: "90%" },
              pb: 5,
            }}
          >
            <Typography sx={{ color: "#fff", textAlign: "center" }}>
              Choose a suitable plan for your restaurant
            </Typography>

            <Box sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ bgcolor: "#fff", borderRadius: "8px", p: 3 }}>
                    <Typography sx={{ color: "#6C6C6C" }}>Dollop</Typography>
                    <Typography
                      sx={{ mt: 2, fontWeight: 700, fontSize: "30px" }}
                      color="primary"
                    >
                      Free
                    </Typography>
                    <Typography color="primary">
                      + booking deposit fee (starting at N500 per diner)
                    </Typography>

                    <Box sx={{ mt: 4 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <IoCheckmarkCircle style={{ color: "#BC172F" }} />{" "}
                        <Typography sx={{ color: "#717171" }}>
                          Reservation, waitlist, and table management
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 2,
                        }}
                      >
                        <IoCheckmarkCircle style={{ color: "#BC172F" }} />{" "}
                        <Typography sx={{ color: "#717171" }}>
                          Menu Management
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 2,
                        }}
                      >
                        <IoCheckmarkCircle style={{ color: "#BC172F" }} />{" "}
                        <Typography sx={{ color: "#717171" }}>
                          Ticketed events, experiences, and add-ons
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 2,
                        }}
                      >
                        <IoCheckmarkCircle style={{ color: "#BC172F" }} />{" "}
                        <Typography sx={{ color: "#717171" }}>
                          Post-dining guest survey management
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ mt: 9 }}>
                      <Link to="/get-started">
                        <Button
                          variant="contained"
                          sx={{ px: 10, borderRadius: "10px", py: 1 }}
                        >
                          Request Info
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box sx={{ bgcolor: "#fff", borderRadius: "8px", p: 3 }}>
                    <Typography sx={{ color: "#6C6C6C" }}>
                      Splash{" "}
                      <span
                        style={{
                          background: "#BC172F",
                          padding: "2px  4px ",
                          borderRadius: "2px",
                          fontSize: "12px",
                          color: "#fff",
                        }}
                      >
                        {" "}
                        + 30 day free trial
                      </span>
                    </Typography>
                    <Typography
                      sx={{ mt: 2, fontWeight: 700, fontSize: "30px" }}
                      color="primary"
                    >
                      ₦30,000{" "}
                      <span style={{ fontSize: "12px", fontWeight: 300 }}>
                        monthly per restaurant
                      </span>
                    </Typography>
                    <Typography color="primary">
                      {" "}
                      + booking deposit fee (starting at N350 per diner)
                    </Typography>

                    <Box sx={{ mt: 4 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                        }}
                      >
                        <IoCheckmarkCircle style={{ color: "#BC172F" }} />{" "}
                        <Typography sx={{ color: "#717171" }}>
                          All of Dollop plan features
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 2,
                        }}
                      >
                        <IoCheckmarkCircle style={{ color: "#BC172F" }} />{" "}
                        <Typography sx={{ color: "#717171" }}>
                          Data & Analytics Dashboards
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 2,
                        }}
                      >
                        <IoCheckmarkCircle style={{ color: "#BC172F" }} />{" "}
                        <Typography sx={{ color: "#717171" }}>
                          Guest Database with preferences, special occassions
                          and visits history
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: 2,
                          mt: 2,
                        }}
                      >
                        <IoCheckmarkCircle style={{ color: "#BC172F" }} />{" "}
                        <Typography sx={{ color: "#717171" }}>
                          Multiple locations management
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ mt: 5 }}>
                      <Link to="/get-started">
                        <Button
                          variant="contained"
                          sx={{ px: 10, borderRadius: "10px", py: 1 }}
                        >
                          Request Info
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            sx={{
              margin: "0 auto",
              width: { lg: "80%", md: "80%", sm: "85%", xs: "100%" },
              bgcolor: "#fff",
              borderRadius: { lg: "0px", md: "0px", sm: "px", xs: "0px" },
              py: 10,
            }}
          >
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontSize: "18px" }}
            >
              Tell us about your restaurant business
            </Typography>
            <Box sx={{ margin: "0 auto", width: "80%", mt: 3 }}>
              <RequestForm privacy={true} />
            </Box>
          </Box>
        </Box>
        <Footer />
      </motion.div>
    </>
  );
};

export default Pricing;
