import React, { useContext, useEffect, useState } from "react";
import emptyIcon from "../../../assets/emptyRes.svg";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getMenuList } from "../../../axios/api";
import CreateMenu from "../../Components/Menu/CreateMenu";
import MenuTable from "../../Components/Menu/MenuTable";
import { MdOutlineRefresh } from "react-icons/md";
import Loader from "../../Components/Common/Loader";
import NoMobile from "../NoMobile";
import { Helmet } from "react-helmet-async";
import SEO from "../../../Utility/SEO";
import { ThemeContext } from "../../theme/ThemeContextProvider";
import { LuSearch } from "react-icons/lu";

const Menu = () => {
  const [show, setShow] = useState(true);
  const [tab, setTab] = useState("Breakfast");
  const [filteredData, setFilteredData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleGetMenu = async () => {
    setIsLoading(true);
    await getMenuList()
      .then((res) => {
        setIsLoading(false);

        setTableData(res?.data?.result);

      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetMenu();
  }, []);

  const {isDarkTheme} = useContext(ThemeContext)
  return (
    <>
      <SEO
        title="Reisty | Restaurant Menu Management"
        description="Enhance your restaurant management with Reisty’s intuitive dashboard and real-time insights."
        name="Reisty"
        type="article"
          canonicalUrl="https://restaurant.reisty.com/dashboard/menu"
      />

      {isMobile ? (
        <>
          <NoMobile />
        </>
      ) : (
        <>
          {loading && <Loader />}
          {!tableData || tableData?.length === 0 ? (
            <>
              <Box
                sx={{
                  // border: "1px solid red",
                  height: "80vh",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Box align="center" sx={{ width: "70%" }}>
                  <img src={emptyIcon} alt="icon" />
                  <Typography
                    sx={{fontSize: "16px", fontWeight: 400 }}
                  >
                    No menu list found
                  </Typography>
                  <Typography
                    sx={{
                     
                      fontSize: "14px",
                      fontWeight: 300,
                      mt: 2,
                    }}
                  >
                    You have no menu list. Click on “add new” to add a new menu
                  </Typography>
                  <CreateMenu />
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  bgcolor: "#1A1A1A",
                  p: 2,
                  px: 3,
            borderBottom: "0.2px solid #ccccccb7",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                                background: isDarkTheme ? "#1a1a1a" : "#ffff",
                }}
              >
  <Box>
                  <Typography sx={{       fontSize: { lg: "24px", md: "24px", sm: "12px", xs: "12px" },
          fontWeight: 700,
          fontFamily: "optima", }}>Menu</Typography>
          <Typography variant="coption" sx={{fontSize:'12px'}}>Manage and customize your restaurant's menu, including item details, prices, and availability.</Typography>
  </Box>
                <CreateMenu variant={true} />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  px: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{ display: "flex", columnGap: 3, alignItems: "center" }}
                >
                  <Button
                    sx={{
                      border: "1px solid #fff",
                      borderRadius: 2,
        
                      fontSize: "12px",
                      px: 3,
                    }}
                  >
                    <MdOutlineRefresh
                      style={{ marginRight: "5px", fontSize: "16px" }}
                    />
                    Refresh
                  </Button>
      
                </Box>
                <Box
                  sx={{ display: "flex", columnGap: 3, alignItems: "center" }}
                >
                  <TextField placeholder="Search a menu item" size="small"  InputProps={{
                    style:{
                      borderRadius:'8px',
                      fontSize:'12px'
                    }
                  }} />
                  <IconButton
                    variant="contained"
                    sx={{
                      width: "35px",
                      p: 0,
                      height: "35px",
                      borderRadius: '10px',
                      bgcolor: "#BC172F",
                    }}
                  >
                    <LuSearch style={{ color: "#fff" , fontSize
                      :'20px'
                    }} />
                  </IconButton>
                </Box>
              </Box>
              <Box sx={{ mt: 3, p: 3 }}>
                <Box
                  sx={{
                    px: 2,
                    bgcolor: "#BC172F",
                    borderRadius: "10px  10px 0px 0px",
                  }}
                >
                  <List sx={{ display: "flex", width: "40%" }}>
                    <ListItemButton
                      onClick={() => setTab("Breakfast")}
                      selected={tab === "Breakfast"}
                      sx={{
                        textAlign: "center",
                   
                        "&.Mui-selected": { borderBottom: "4px solid ", borderColor:'text.primary' },
                      }}
                    >
                      Breakfast
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setTab("Brunch")}
                      selected={tab === "Brunch"}
                      sx={{
                        textAlign: "center",
                   
                        "&.Mui-selected": { borderBottom: "4px solid ", borderColor:'text.primary'  },
                      }}
                    >
                      Brunch
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setTab(" Lunch")}
                      selected={tab === " Lunch"}
                      sx={{
                        textAlign: "center",
                   
                        "&.Mui-selected": { borderBottom: "4px solid ", borderColor:'text.primary'  },
                      }}
                    >
                      Lunch
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => setTab("Dinner")}
                      selected={tab === "Dinner"}
                      sx={{
                        textAlign: "center",
                   
                        "&.Mui-selected": { borderBottom: "4px solid ", borderColor:'text.primary' },
                      }}
                    >
                      Dinner
                    </ListItemButton>
                  </List>
                </Box>
                <MenuTable data={tableData} />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Menu;
