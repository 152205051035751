import React from "react";
import { RadioGroup, FormControlLabel, Checkbox, Radio } from "@mui/material";

const CustomRadio = ({ onChange, label, checked }) => {
  return (
    <>
      <FormControlLabel
        sx={{
    
          "& > span": {
            fontWeight: 400,
            fontFamily: "outfit",
            fontSize: "13px",
  
          },
        }}
        onChange={onChange}
        name={label}
        value={label}
        control={<Radio checked={checked} />}
        label={label}
      />
    </>
  );
};

export default CustomRadio;
