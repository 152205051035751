import * as React from 'react';
import {useNavigatorOnLine} from '../store/useNavigatorOnLine'
import {useDisplay} from '../store/useDIsplay'
import Modal from '@mui/material/Modal';
import { Box, Typography } from '@mui/material';

const style = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#160b0b',
boxSizing:'border-box',
  boxShadow: 24,
  p: 2,
  borderRadius:'8px'
};

export const NetworkStatusIndicator = () => {
    const { show, close } = useDisplay();
    const isOnline = useNavigatorOnLine();
    const firstUpdate = React.useRef(true);
     const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [mesage, setMessage] = React.useState("")
  

    React.useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        isOnline
            ? setOpen(false)
            : setOpen(true);
    }, [ show, isOnline]);

    React.useEffect(()=>{
     setTimeout(()=>{
      setOpen(false);
     }, [1000])
    }, [])

    return (
     <>
     <Modal
      open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
     >
 <Box sx={style}>
        {
         isOnline ? (
            <Typography sx={{textAlign:'center', color:'#f4c7c7'}}>
         You're back Online!
          </Typography>
         ):(
          <>            <Typography  sx={{textAlign:'center', color:'#f4c7c7'}}>
         You're currently offline
          </Typography>
                <Typography sx={{textAlign:'center', color:'#f4c7c7', mt:1, fontSize:'10px'}}>
   please connect to the internet and try again
          </Typography>
          </>

         )
        }
    
        </Box>
     </Modal>
     </>
    );
};