import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Grid, InputLabel, Skeleton, Modal } from "@mui/material";
import { IoChevronBack } from "react-icons/io5";
import { deleteMember, editMemberPermissions, getMemberPermision } from "../../../axios/api";
import { TbClockHour2 } from "react-icons/tb";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoShieldCheckmark } from "react-icons/io5";
import { TbTableOptions } from "react-icons/tb";
import { MdOutlineEventAvailable } from "react-icons/md";
import { SlPeople } from "react-icons/sl";
import { MdOutlineAnalytics } from "react-icons/md";
import { RiContactsBookLine } from "react-icons/ri";
import { GrServer } from "react-icons/gr";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import Loader from "../Common/Loader";
import { useSnackbar } from "notistack";
import { TiUserDeleteOutline } from "react-icons/ti";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#333',
borderRadius:'10px',
  boxShadow: 24,
  p: 4,
};

const MembersInfo = ({ handleBack, editDetails }) => {
  const [MemberPermissions, setMemberPermissions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

        const handleAlert = (variant, message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleGetMemberPermissions = async () => {
    setIsLoading(true);
    await getMemberPermision(editDetails?.Id)
      .then((res) => {
        setIsLoading(false)
 
        if(res?.data?.status){

          setMemberPermissions(res?.data?.result)
        }
      })
      .catch((err) => {

           setIsLoading(false)
      });
  };

  useEffect(() => {
    handleGetMemberPermissions();
  }, [editDetails]);

  const [updatedPermissions, setUpdatedPermissions] = useState(null)

  const default_permission =[
    {
ClaimType:'AccessReservationHour',
ClaimValue:true,
icon:<TbClockHour2 style={{ color: "#ccc", fontSize: "30px" }} />
    },
    {
ClaimType:'AccessTablesAndFloors',
ClaimValue:true,
icon:<TbTableOptions  style={{ color: "#ccc", fontSize: "30px" }} />
    },
    {
ClaimType:'AccessExperiencesAndEvents',
ClaimValue:false,
icon:<MdOutlineEventAvailable style={{ color: "#ccc", fontSize: "30px" }} />
    },
    {
ClaimType:'AccessGuestBook',
ClaimValue:true,
icon:<SlPeople style={{ color: "#ccc", fontSize: "30px" }} />
    },
    {
ClaimType:'AccessAnalytics',
ClaimValue:true,
icon:<MdOutlineAnalytics style={{ color: "#ccc", fontSize: "30px" }} />
    },
    {
ClaimType:'AccessCRM',
ClaimValue:true,
icon:<RiContactsBookLine style={{ color: "#ccc", fontSize: "30px" }}/>
    },
    {
ClaimType:'AccessReservations',
ClaimValue:false,
icon:<GrServer style={{ color: "#ccc", fontSize: "30px" }} />
    },
    {
ClaimType:'CanRecieveReservationEmail',
ClaimValue:true,
icon:<MdOutlineMarkEmailRead style={{ color: "#ccc", fontSize: "30px" }} />
    },
  ]
  
  useEffect(()=>{
    if(MemberPermissions){
      const updatedPermissions = default_permission.map((defaultPerm) => {
    const userClaim = MemberPermissions.find(claim => claim.ClaimType === defaultPerm.ClaimType);

    return {
        ...defaultPerm,
        ClaimValue: userClaim ? userClaim.ClaimValue : false,
    };
});

setUpdatedPermissions(updatedPermissions)
    }
  },[MemberPermissions])

  const updateClaimValue = (claimType, newClaimValue) => {
    const updated = updatedPermissions.map((permission) => {
      if (permission.ClaimType === claimType) {
        return { ...permission, ClaimValue: newClaimValue };
      }
      return permission;
    });

    setUpdatedPermissions(updated);
  };

  const convertToObject = (array) => {
    return array.reduce((acc, curr) => {
      acc[curr.ClaimType] = curr.ClaimValue;  // Add ClaimType as key and ClaimValue as value
      return acc;
    }, {});
  };
  

  const [editLoading, setEditLoading] = useState(false);

  const handleEditPermissions = async () =>{
     const convertedObject = convertToObject(updatedPermissions);

    setEditLoading(true)

    await editMemberPermissions(editDetails?.Id, convertedObject)
    .then((res)=>{

      if(res?.data?.status){
           handleAlert("success", `${res?.data?.success_message}`);
           handleGetMemberPermissions();
      }else{
          handleAlert("error", `${res?.data?.error_message}`);
      }
      setEditLoading(false)
    }).catch((err)=>{
          handleAlert("error", `${err?.response?.data?.
error_message}`);

            setEditLoading(false)
    })
  }
  
  const handleDeleteMember = async () =>{
    setEditLoading(true)

    await deleteMember
    (editDetails?.Id)
    .then((res)=>{

      setEditLoading(false)
      if(res?.data?.status){
                   handleAlert("success", `${res?.data?.success_message}`);
                   handleBack()
                   handleClose()
      }else{
                  handleAlert("error", `${res?.data?.error_message}`);
      }
    }).catch((err)=>{

            setEditLoading(false)
                      handleAlert("error", `${err?.response?.data?.
error_message}`);
    })
  }


  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
    {
      editLoading && <Loader/>
    }
      <Box
        sx={{
          bgcolor: "#1a1a1a",
          border: "1px solid #fff",
          py: 1,
          px: 4,
          borderLeft: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "#fff", fontWeight: 500 }}>
          Member Permission
        </Typography>

        <Button
          variant="contained"
          startIcon={<IoChevronBack />}
          onClick={handleBack}
        >
          Back to Account
        </Button>
      </Box>
      <Box sx={{ px: 4, mt: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>
              Member Details
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#fff" }}>
              Our map feature is linked to your restaurant name and address.
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
            <Button
            startIcon={<TiUserDeleteOutline />}
            onClick={handleOpen}
              variant="contained"
              sx={{
                color: "#fff",
                width:'100px',
                py:1,
                borderRadius:'10px'
              }}
            >
              Delete
            </Button>
            {/* <Button
              variant="outlined"
              sx={{
                bgcolor: "#1a1a1a",
                border: "1px solid #dadada",
                color: "#fff",
              }}
            >
              Edit
            </Button> */}
          </Box>
        </Box>
        <Box sx={{ mt: 2, bgcolor: "#1a1a1a", borderRadius: "8px", p: 2 }}>
          <Box
            sx={{ display: "flex", columnGap: 4, alignItems: "center", mb: 2 }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 500,
                width: "20%",
                fontSize: "12px",
              }}
            >
              Name
            </Typography>
            <Typography
              sx={{ color: "#fff", fontWeight: 500, fontSize: "12px" }}
            >
              {" "}
              {`${editDetails?.FirstName} ${editDetails?.LastName}`}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", columnGap: 4, alignItems: "center", mb: 2 }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 500,
                width: "20%",
                fontSize: "12px",
              }}
            >
              Email Address:
            </Typography>
            <Typography
              sx={{ color: "#fff", fontWeight: 500, fontSize: "12px" }}
            >{`${editDetails?.Email || "--"}`}</Typography>
          </Box>
          <Box sx={{ display: "flex", columnGap: 4, alignItems: "center" }}>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 500,
                width: "20%",
                fontSize: "12px",
              }}
            >
              Role:
            </Typography>
            <Box sx={{ display: "flex", columnGap: 4, alignItems: "center" }}>
              <Typography
                sx={{ color: "#fff", fontWeight: 500, fontSize: "12px" }}
              >
                {editDetails?.RoleDepartment || "--"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Box>
            <Typography sx={{ color: "#fff", fontWeight: 500 }}>
              Member Permissions
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#fff" }}>
              Customize permissions for this member
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
            {/* <Button
              variant="outlined"
              sx={{
                bgcolor: "#1a1a1a",
                border: "1px solid #dadada",
                color: "#fff",
              }}
            >
              Edit
            </Button> */}
          </Box>
        </Box>
        <Box sx={{ mt: 2, bgcolor: "#1a1a1a", borderRadius: "8px", p: 2 }}>
          <Box sx={{ margin: "0 auto", width: "75%", mt: 4 }}>
            <Grid container spacing={2}>
              {
                isLoading ? (
                  <>
                  {
                    [1,2,3,4,]?.map((item)=>(
                      <Grid item lg={3} md={4}>
                        <Box>
                          <Skeleton animation="wave" width={150} height={200}  sx={{borderRadius:'12px'}}/>
                
                        </Box>
                      </Grid>
                    ))
                  }
                  </>
                ):(
                  <>
                        {
            updatedPermissions?.map((permision, item)=>{
              return(
                   <Grid item lg={3} md={4} key={item}>
                <Box
                onClick={()=>updateClaimValue(permision?.ClaimType, !permision?.ClaimValue)}
                  sx={{
                    border: "1px solid ",
                    borderColor:permision?.ClaimValue ? "primary.main" :"#cccccc1a",
                    borderRadius: "12px",
                    p: 2,
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    jistifyContent: "center",
                    cursor:'pointer',
                    height:'150px',
                    transition:'0.2s all linear',
                    '&:hover':{
                      border:'0.5px solid ',
                      borderColor:'primary.main',
                      bgcolor:'#000'
                    }
                  }}
                >
                  <Box sx={{ width: "100%", height:'30px' }} align="right">
                    {
                      permision?.ClaimValue && (

                        <IoShieldCheckmark style={{ color: "#BC172F", fontSize:'20px' }} />
                      )
                    }
                  </Box>
                  <Box
                    sx={{
                      bgcolor: "#333333b7",
                      borderRadius: "50%",
                      width: "50px",
                      height: "50px",
                      display: "grid",
                      placeContent: "center",
                    }}
                  >
           {permision?.icon}
                  </Box>
                  <InputLabel sx={{ mt: 1, color: "#ccc", fontSize:'12px' }}>
                    {permision?.ClaimType}
                  </InputLabel>
                </Box>
              </Grid>
              )
            })
           }
                  </>
                )
              }
     
            </Grid>
          </Box>
          <Box sx={{mt:5, mb:4}} align="center">
            <Button variant="contained" onClick={handleEditPermissions} sx={{py:2, borderRadius:'8px', width:'200px'}}>Save Changes</Button>
          </Box>
        </Box>
      </Box>


       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography sx={{color:'#fff', fontSize:'12px', textAlign:'center', lineHeight:'15px'}}>
       Are you sure you want to delete <b style={{color:'#bc172f'}}>      {`${editDetails?.FirstName} ${editDetails?.LastName}`} </b>from the member list?


          </Typography>
          <Typography  sx={{  color:'#ccc', fontSize:'10px' , mt:1}}>
This settings will be deleted immediately, you can’t undo this action.
          </Typography>

          <Box sx={{mt:3, display:'flex', columnGap:3}}>
            <Button variant="outlined" fullWidth onClick={handleClose} sx={{borderColor:'#ccc', color:'#ccc'}}>Cancel</Button>
            <Button variant="contained" fullWidth onClick={handleDeleteMember}>Delete</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MembersInfo;
